import React, { FC } from 'react'
import Slider from '@mui/material/Slider'
import { Stack, styled } from '@mui/material'
import SliderValue from './SliderValue'
import { FORMAT_VALUE_LABEL_SLIDER } from '../../../utils/constants'

const CustomSliderSingleValue = styled(Slider)`
  .MuiSlider-track {
    background: #000000;
    border-color: #000000;
    height: 8px;
  }

  .MuiSlider-rail {
    background: #E4E4E4;
    height: 8px;
  }

  .MuiSlider-thumb {
    color: #FFFFFF;
    border: 5px solid #000000;
    height: 24px;
    width: 24px;
  }

  .MuiSlider-valueLabel {
    color: #FFFFFF;
    background: #000000;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    font-weight: 400;
    padding: 8px 12px;
    border-radius: 6px;
  }
`

const SliderSingleValue: FC<SliderSingleValueType> = ({ min, max, step, defaultValue, setSliderValue, leftLabel , rightLabel, format }) => {
	let timeout: NodeJS.Timeout
	return (
		<>
			<Stack alignItems="center" justifyContent="center" flexDirection="row" alignSelf="stretch" width="100%">
				<SliderValue textPosition="right">{leftLabel}</SliderValue>
				<CustomSliderSingleValue
					valueLabelDisplay="on"
					valueLabelFormat={value => {
						switch(format) {
							case FORMAT_VALUE_LABEL_SLIDER.ANGLE:
								return `${value}°`
							default:
								return value
						}
					}}
					defaultValue={defaultValue}
					min={min}
					max={max}
					step={step}
					onChange={(event, value) => {
						if (timeout) {
							clearTimeout(timeout)
						}
						timeout = setTimeout(() => {
							setSliderValue(value)
						}, 1000)
					}}
				/>
				<SliderValue textPosition="left">{rightLabel}</SliderValue>
			</Stack>
		</>
	)
}

export default SliderSingleValue