import { createTheme } from '@mui/material/styles'

/**
 * Thème général de l'application
 */
export const parkerFoTheme = createTheme({
	palette: {
		primary: {
			main: '#000000'
		},
		secondary: {
			main: '#FFF'
		},
		recap: {
			main: '#064E3B'
		},
		info: {
			main: '#E4E4E4'
		},
		text: {
			primary: '#000',
			secondary: '#FFF'
		},
		navbar: {
			startGradient: '#000000',
			endGradient: '#FFB91D'
		},
		success: {
			main: '#10B981'
		},
		assemblyPanel: {
			main: '#F9F9F9',
			card: {
				active: '#FFF',
				disable: '#E4E4E4',
				item: {
					active: '#FFD760',
					disable: 'transparent'
				}
			}
		},
		selectionDone: {
			background: '#D1FAE5',
			check: '#10B981'
		}
	},
	typography: {
		button: {
			fontWeight: 600,
			fontStyle: 'normal'
		}
	}
})