import { styled, Typography } from '@mui/material'

const FittingInformation = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ theme, isSelected }) => ({
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: '18px',
		fontFamily: 'Inter',
		color: isSelected ? theme.palette.secondary.main : theme.palette.primary.main
	})
)

export default FittingInformation