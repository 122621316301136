import { styled, Typography } from '@mui/material'

const LabelSubtitle = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'color'
	}
)<{ color?: string }>(
	({ color, theme }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '13px',
		lineHeight: '16px',
		color: color ? color : theme.palette.text.primary
	})
)

export default LabelSubtitle