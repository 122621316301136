import React, {createContext, useEffect, useState} from 'react'
import { CurrentStepContextType } from '../../@types/contextes'
import { CurrentStepType } from '../../@types/constants'
import { INITIAL_STEP } from '../constants'
import {refreshContext} from "../utils";

export const CurrentStepContext = createContext<CurrentStepContextType>({
	currentStep: {
		rank: 1,
		subStepKey: 'transportedFluid',
		lastSubStep: false,
		firstSubStep: true,
		values: undefined
	},
	updateCurrentStep(): void {
	}
})

export const CurrentStepContextProvider = (
	{
		children
	}: any
) => {
	const [currentStep, setCurrentStep] = useState<CurrentStepType>(INITIAL_STEP)

	useEffect(() => {
		setCurrentStep(refreshContext())
	}, [])

	const updateCurrentStep = (newCurrentStep: CurrentStepType) => {
		setCurrentStep(newCurrentStep)
	}

	return <CurrentStepContext.Provider value={{ currentStep, updateCurrentStep }}>
		{children}
	</CurrentStepContext.Provider>
}
