import { styled } from '@mui/material'

const NavbarButton = styled('button')<{ backgroundColor: string }>(
	({ backgroundColor }) => ({
		outline: 'inherit',
		border: 'none',
		padding: '10px 20px',
		backgroundColor: backgroundColor,
		borderRadius: '6px',
		gap: '12px',
		margin: '0 12px',
		cursor: 'pointer'
	})
)

export default NavbarButton
