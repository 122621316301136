import React, { FC, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { CurrentStepContext } from '../../../utils/contextes/CurrentStepContext'
import { Link, Stack, styled, Typography } from '@mui/material'
import { RxDotFilled } from 'react-icons/rx'
import AddIcon from '@mui/icons-material/Add'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import SelectorButton from '../../buttons/SelectorButton'
import { DistributorContext } from '../../../utils/contextes/DistributorContext'
import { clearLocalStorage } from '../../../utils/selectorUtils'
import ChangeSelectionModal from '../../modals/selection/ChangeSelectionModal'

const Container = styled(Stack)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	backgroundColor: '#FFD760',
	borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
	position: 'fixed',
	width: '-webkit-fill-available',
	zIndex: 1
})

const Content = styled(Stack)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '24px 48px',
	gap: '48px',
	width: '95%',
	transition: 'width 1s'
})

const BurgerButton = styled(Link)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: '#F9BA00',
	borderRadius: '24px',
	padding: '12px 14px'
})

const Title = styled(Typography)(
	({ theme }) => ({
		fontFamily: 'DIN_2014',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '24px',
		lineHeight: '32px',
		color: theme.palette.primary.main
	})
)

const SelectorNavbar: FC<SelectorNavType> = (
	{
		primaryLabel,
		secondaryLabel = null,
		assemblyPanelVisible,
		setAssemblyPanelVisibility,
		actionFirstBtn,
		actionSecondBtn
	}
) => {
	const { t, i18n } = useTranslation()
	const context = useContext(CurrentStepContext)
	const { token } = useContext(DistributorContext)

	const [showModal, setShowModal] = useState(false)

	const disabledState = useMemo(() => !context.currentStep.values || (context.currentStep.values && context.currentStep.values.length < 1), [context.currentStep.values])

	return (
		<Container>
			<Content>
				<Stack flexDirection="row" alignItems="center">
					{(!assemblyPanelVisible && context?.currentStep?.subStepKey !== 'recap') && (
						<BurgerButton onClick={() => setAssemblyPanelVisibility(true)}>
							<AiOutlineUnorderedList />
						</BurgerButton>
					)}
					<Stack divider={<RxDotFilled size={24} />} flexDirection="row" alignItems="center" marginLeft="12px" gap="10px">
						<Title>{primaryLabel}</Title>
						<Title>{secondaryLabel}</Title>
					</Stack>
				</Stack>
				{context.currentStep.subStepKey === 'recap' ? (
					<SelectorButton color="primary" variant="outlined" startIcon={<AddIcon />} onClick={() => setShowModal(true)}>
						{t('screens.selector.assembly.recap.newConfiguration')}
					</SelectorButton>
				) : (
					<Stack flexDirection="row" alignItems="flex-start" padding="0" gap="12px">
						{!context?.currentStep?.firstSubStep && (
							<SelectorButton color="primary" startIcon={<ArrowBackIosIcon color="primary" />} variant="outlined" onClick={actionSecondBtn} lowPadding={i18n.language.includes('fr')}>
								{t('global.buttons.previous')}
							</SelectorButton>
						)}
						<SelectorButton color="primary" disabled={disabledState} endIcon={<ArrowForwardIosIcon color={disabledState ? 'disabled' : 'secondary'} />} variant="contained"
						                onClick={actionFirstBtn}>{t('global.buttons.next')}</SelectorButton>
					</Stack>
				)}
				<ChangeSelectionModal
					open={showModal}
					handleClose={() => setShowModal(false)}
					handleConfirm={() => {
						clearLocalStorage()
						window.location.href = `/selector?token=${token}`
					}}
				/>
			</Content>
		</Container>
	)
}

export default SelectorNavbar
