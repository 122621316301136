import React from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/typo/PageTitle'
import PageSubTitle from '../../components/typo/PageSubTitle'
import { Stack, styled, Typography } from '@mui/material'
import pageBackground from '../../assets/img/global/background.png'

const PageContainer = styled(Stack)({
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	backgroundImage: `url(${pageBackground})`,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundAttachment: 'fixed',
	backgroundSize: 'cover',
	alignItems: 'center',
	height: 'calc(100vh - 90px - 128px)'
})

const MessageContainer = styled(Stack)(
	({ theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '24px',
		gap: '10px',
		backgroundColor: theme.palette.secondary.main,
		border: '1px solid rgba(0, 0, 0, 0.1)',
		borderRadius: '12px',
		color: theme.palette.primary.main
	})
)

const Message = styled(Typography)(
	({ theme }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '16px',
		lineHeight: '22px',
		color: theme.palette.primary.main
	})
)

const NotFound = () => {
	const { t } = useTranslation()

	return (
		<PageContainer>
			<PageTitle>{t('screens.notFound.title')}</PageTitle>
			<PageSubTitle>{t('screens.notFound.subtitle')}</PageSubTitle>
			<MessageContainer>
				<Message>{t('screens.notFound.explication')}</Message>
				<Message>{t('screens.notFound.contact')}</Message>
			</MessageContainer>
		</PageContainer>
	)
}

export default NotFound
