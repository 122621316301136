import React from 'react'
import { HiOutlineEllipsisVertical } from 'react-icons/hi2'
import { Link, Stack, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { HOME_LINKS } from '../../modules/selector/utils/constants'

const Container = styled(Stack)(
	({theme}) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'flex-start',
	boxSizing: 'border-box',
	backgroundColor: theme.palette.primary.main,
	padding: '0 24px'
})
)

const FooterLink = styled(Link)(
	({ theme }) => ({
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '18px',
		color: theme.palette.text.secondary,
		padding: '55px 18px'
	})
)

const Footer = () => {
	const { t } = useTranslation()
	return (
		<Container divider={<HiOutlineEllipsisVertical color="#828282" />}>
			<FooterLink underline="none" href={HOME_LINKS.CONTACT} target="_blank" rel="noopener noreferrer">{t('components.footer.contact')}</FooterLink>
			<FooterLink underline="none" href={HOME_LINKS.PRIVACY_POLICIES} target="_blank" rel="noopener noreferrer">{t('components.footer.privacyPolicies')}</FooterLink>
			<FooterLink underline="none" href={HOME_LINKS.PERSONAL_DATA_PRIVACY} target="_blank" rel="noopener noreferrer">{t('components.footer.personalDataPrivacy')}</FooterLink>
			<FooterLink underline="none" href={HOME_LINKS.COOKIES} target="_blank" rel="noopener noreferrer">{t('components.footer.cookies')}</FooterLink>
			<FooterLink underline="none" href={HOME_LINKS.LEGAL_TERMS} target="_blank" rel="noopener noreferrer">{t('components.footer.legalTerms')}</FooterLink>
			<FooterLink underline="none" href={HOME_LINKS.TUTORIAL} target="_blank" rel="noopener noreferrer">{t('components.footer.tutorial')}</FooterLink>
		</Container>
	)
}
export default Footer
