import React, { FC } from 'react'
import { Stack, styled } from '@mui/material'
import BasicCheckbox from './BasicCheckbox'

const CheckRadioButtonRow = styled(Stack, {
	shouldForwardProp: (prop: string) => prop !== 'isSelected' && prop !== 'height' && prop !== 'gap' && prop !== 'padding' && prop !== 'cursor'
})<{ isSelected: boolean, height: string, gap: string, padding: string, cursor: string }>(
	({ isSelected, height, theme, gap, padding, cursor }) => ({
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: padding,
		gap: gap,
		borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
		alignSelf: 'stretch',
		backgroundColor: isSelected ? theme.palette.primary.main : theme.palette.secondary.main,
		color: isSelected ? theme.palette.secondary.main : theme.palette.primary.main,
		fontFamily: 'Inter',
		fontSize: '14px',
		fontWeight: 600,
		fontStyle: 'normal',
		lineHeight: '20px',
		borderRadius: isSelected ? '6px' : 0,
		height: height,
		cursor: cursor
	})
)

const CheckRadioButton: FC<CheckRadioButtonType> = ({ children, value, itemSelected, setSelection, height = '42px', gap = '16px', padding = '16px', cursor = 'auto' }) => {
	return (
		<CheckRadioButtonRow padding={padding} gap={gap} height={height} onClick={() => setSelection(value)} isSelected={itemSelected === value} cursor={cursor}>
			<BasicCheckbox checked={itemSelected === value} color="secondary" />
			{children}
		</CheckRadioButtonRow>
	)
}
export default CheckRadioButton