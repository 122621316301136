import React, { FC, useMemo } from 'react'
import { BsCircleFill } from 'react-icons/bs'
import { Select, Stack, styled } from '@mui/material'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import LabelSubtitle from '../../typo/LabelSubtitle'
import SelectOption from './SelectOption'
import { t } from 'i18next'

const SelectContainer = styled(Stack)(
	({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		paddingLeft: '18px',
		border: '1px solid #BCBCBC',
		boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
		borderRadius: '6px',
		backgroundColor: theme.palette.secondary.main,
		height: '40px'
	})
)

const SelectColorName = styled(Select)(
	({ theme }) => ({
		color: theme.palette.primary.main,
		fontSize: '13px',
		fontWeight: 400,
		lineHeight: '16px',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		width: '95%',
		boxShadow: 'none',
		marginLeft: '12px',
		padding: '18px 0 15px 15px',
	})
)

const ColorSelect: FC<ColorSelectType> = ({ label, colors, colorSelected, setColorSelected }) => {
	const colorPastille = useMemo(() => <BsCircleFill color={colorSelected} />, [colorSelected])

	return (
		<>
			<LabelSubtitle marginBottom="4px">{label}</LabelSubtitle>
			<SelectContainer>
				{colorSelected !== '-' && (
					colorPastille
				)}
				<SelectColorName variant="standard" disableUnderline value={colorSelected} onChange={e => setColorSelected(e.target.value as string)} IconComponent={UnfoldMoreIcon}>
					{colors.map((color: string) => <SelectOption value={color} key={color}>{color}</SelectOption>)}
				</SelectColorName>
			</SelectContainer>
		</>
	)
}

export default ColorSelect
