import React, { FC, useMemo } from 'react'
import FittingItem from './fitting/FittingItem'
import OtherConfigurationItem from './other/OtherConfigurationItem'
import HoseItem from '../../../../../../modules/selector/components/hoseSelection/body/hoseItem/HoseItem'
import { Stack, styled } from '@mui/material'
import { SOURCE_VALUE } from '../../../../../../modules/selector/utils/constants'
import { generateHose } from '../../../../../../modules/selector/utils/utils'
import Loader from '../../../../../global/Loader'

const Container = styled(Stack)({
	boxSizing: 'border-box',
	flexDirection: 'column',
	alignItems: 'flex-start',
	alignSelf: 'stretch',
	borderTop: '1px solid rgba(0, 0, 0, 0.1)',
	marginTop: '24px'
})

const Row = styled(Stack)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	alignSelf: 'stretch',
	padding: '24px',
	gap: '16px',
	borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
})

const RecapProductItem: FC = () => {
	const hose = useMemo(() => generateHose(), [])
	return !hose ? <Loader /> : (
		<Container>
			<Row><HoseItem hose={hose} isSelected={false} /></Row>
			<Row><FittingItem source={SOURCE_VALUE.FITTING_A} /></Row>
			<Row><FittingItem source={SOURCE_VALUE.FITTING_B} /></Row>
			<Row justifyContent="space-between"><OtherConfigurationItem /></Row>
		</Container>
	)
}

export default RecapProductItem