import React from 'react'
import { MenuItem, styled } from '@mui/material'

const SelectOption = styled(MenuItem)(
	({ theme }) => ({
		color: theme.palette.primary.main,
		fontSize: '13px',
		fontWeight: 400,
		lineHeight: '16px',
		fontFamily: 'Inter',
		fontStyle: 'normal'
	})
)

export default SelectOption