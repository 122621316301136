import React, { FC } from 'react'
import { Stack, styled, Typography } from '@mui/material'

const PartNumberContainer = styled(Stack, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ isSelected, theme }) => ({
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '0 4px',
		gap: '10px',
		background: isSelected ? '#262626' : theme.palette.assemblyPanel.main,
		border: `1px solid ${isSelected ? '#262626' : theme.palette.assemblyPanel.card.disable }`,
		borderRadius: '4px',
		marginBottom: '4px'
	})
)

const PartNumberTypo = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ isSelected }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '10px',
		lineHeight: '16px',
		color: isSelected ? '#BCBCBC' : '#828282'
	})
)

type PartNumberType = {
	isSelected?: boolean,
	partNumber: string
}

const PartNumber: FC<PartNumberType> = ({ isSelected = false, partNumber }) => (
	<PartNumberContainer isSelected={isSelected}>
		<PartNumberTypo isSelected={isSelected}>{partNumber}</PartNumberTypo>
	</PartNumberContainer>
)

export default PartNumber