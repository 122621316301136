import { styled } from '@mui/material'

const LabelTitle = styled('h4')<{padding: string}>(
	({theme, padding}) => ({
		fontFamily: 'DIN_2014',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '20px',
		lineHeight: '28px',
		color: theme.palette.text.primary,
		padding: padding
	})
)

export default LabelTitle