import React, { FC } from 'react'
import { FormControlLabel, styled } from '@mui/material'
import BpRadio from '../BpRadio'

const StretchRadioBtnContainer = styled(FormControlLabel, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ theme, isSelected }) => ({
		display: 'flex',
		backgroundColor: isSelected ? theme.palette.secondary.main : 'transparent',
		flexDirection: 'row',
		alignSelf: 'stretch',
		alignItems: 'center',
		padding: '5px 24px 5px 18px',
		border: `${isSelected ? '3px' : '1px'} solid ${theme.palette.assemblyPanel.card.disable}`,
		borderRadius: '6px',
		color: theme.palette.text.primary,
		height: '42px',
		marginLeft: '12px'
	})
)

const StretchRadioButton: FC<StretchRadioButtonType> = ({ label, value, radioBtnSelected, setRadioBtnSelected }) => {
	return <StretchRadioBtnContainer control={<BpRadio />} label={label} isSelected={radioBtnSelected === value} value={value}  onClick={() => setRadioBtnSelected(value)} checked={radioBtnSelected === value} />
}

export default StretchRadioButton
