import { styled } from '@mui/material'

const SliderZone = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
	alignSelf: 'stretch',
	padding: '48px 15px',
	gap: '0px'
})

export default SliderZone
