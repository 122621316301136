import { Button, styled } from '@mui/material'

const SelectorButton = styled(Button, {
		shouldForwardProp: (prop: string) => prop !== 'lowPadding'
	}
)<{ lowPadding?: boolean }>(
	({ lowPadding }) => ({
		padding: `10px ${lowPadding ? '28px' : '42px'}`,
		textTransform: 'unset',
		borderRadius: '6px',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '20px',
		height: '40px'
	})
)

export default SelectorButton