import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import assemblyLengthSchemaFr from '../../../../assets/img/selector/Overall_Hose_Assembly_Length_Measurement_FR.jpg'
import assemblyLengthSchemaEs from '../../../../assets/img/selector/Overall_Hose_Assembly_Length_Measurement_ES.jpg'
import assemblyLengthSchemaDe from '../../../../assets/img/selector/Overall_Hose_Assembly Length_Measurement_DE.jpg'
import assemblyLengthSchemaUk from '../../../../assets/img/selector/Overall_Hose_Assembly_Length_Measurement_UK.jpg'
import assemblyLengthSchemaIt from '../../../../assets/img/selector/Overall_Hose_Assembly Length_Measurement_IT.jpg'
import { Stack, styled, TextField, Typography } from '@mui/material'
import LabelSubtitle from '../../../../components/typo/LabelSubtitle'
import { useCurrentStepContext } from '../../utils/utils'
import { LOCAL_STORAGE_KEYS } from '../../../../config/enums'
import { CHARACTER_LIMIT_ASSEMBLY_LENGTH } from '../../../../utils/constants'
import { HiOutlineExclamationCircle } from 'react-icons/hi'

const InputZone = styled(Stack)(
	({ theme }) => ({
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '24px',
		gap: '4px',
		backgroundColor: theme.palette.assemblyPanel.main,
		borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
		borderRadius: '12px',
		alignSelf: 'stretch',
		marginTop: '24px'
	})
)

const AssemblyLengthField = styled(TextField)(
	({ theme }) => ({
		'& .MuiInputBase-input': {
			backgroundColor: theme.palette.secondary.main
		},
		'& .MuiTextField-root': {
			backgroundColor: theme.palette.secondary.main
		}
	})
)

const InformationSection = styled(Stack)(
	({ theme }) => ({
		display: 'flex',
		flexDirection: 'row',
		marginTop: '50px',
		padding: '24px',
		alignItems: 'center',
		gap: '24px',
		alignSelf: 'stretch',
		borderRadius: '12px',
		border: `0px solid ${theme.palette.primary.main}`,
		backgroundColor: '#FEE2E2'
	})
)

const TextSection = styled(Stack)({
	display: 'flex',
	flexDirection: 'row',
	gap: '48px',
	flex: '1 0 0',
	alignItems: 'flex-start'
})

const ContentInformationSection = styled(Stack)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: '10px',
	flex: '1 0 0'
})

const Title = styled(Typography)({
	fontFamily: 'DIN 2014',
	fontSize: '24px',
	fontStyle: 'normal',
	fontWeight: 700,
	lineHeight: '32px',
	color: '#7F1D1D'
})

const Message = styled(Typography)({
	color: '#7F1D1D',
	fontFamily: 'Inter',
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: 400,
	lineHeight: '22px'
})

const AssemblyLength = () => {
	const { t, i18n } = useTranslation()
	const { currentStep, updateCurrentStep } = useCurrentStepContext()
	const [assemblyLength, setAssemblyLength] = useState<number | undefined>(0)

	const needFittingAngle = () => {
		let fittingAShape = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SHAPE) ?? null
		let fittingBShape = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_SHAPE) ?? null

		return !!(fittingAShape
			&& fittingBShape
			&& (fittingAShape.includes('°')
				|| fittingAShape.includes('Elbow'))
			&& (fittingBShape.includes('°')
				|| fittingBShape.includes('Elbow')));
	}

	useEffect(() => {
		if (assemblyLength) {
			updateCurrentStep({
				rank: currentStep.rank,
				subStepKey: currentStep.subStepKey,
				lastSubStep: currentStep.lastSubStep,
				firstSubStep: currentStep.firstSubStep,
				values: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH,
						value: assemblyLength
					},
					!needFittingAngle() && {
						localStorageKey: LOCAL_STORAGE_KEYS.FITTINGS_ANGLE,
						value: '0'
					}
				]
			})
		}
	})

	const imageSrc = useMemo(() => {
		 const languageCode = i18n.language.split('-')[0]
		switch (languageCode) {
			case 'fr':
				return assemblyLengthSchemaFr
			case 'de':
				return assemblyLengthSchemaDe
			case 'it':
				return assemblyLengthSchemaIt
			case 'es':
				return assemblyLengthSchemaEs
			case 'en':
				return assemblyLengthSchemaUk
		}
	}, [i18n])

	return (
		<Stack flexDirection="column" alignItems="center" alignSelf="stretch">
			<img src={imageSrc} alt="" style={{ marginTop: '24px', marginBottom: '24px', maxHeight: '161px' }} />
			<InputZone>
				<LabelSubtitle>{t('screens.selector.assembly.assemblyLength.overall')}</LabelSubtitle>
				<AssemblyLengthField
					type="number"
					inputProps={{ maxLength: CHARACTER_LIMIT_ASSEMBLY_LENGTH, pattern: /^-?\d+(?:\.\d+)?$/g  }}
					value={assemblyLength}
					onChange={e => {
						if (e.target.value.length <= CHARACTER_LIMIT_ASSEMBLY_LENGTH) {
							setAssemblyLength(parseInt(e.target.value))
						}
					}}
				/>
				<InformationSection>
					<Stack alignItems="flex-start" alignSelf="stretch">
						<HiOutlineExclamationCircle size="42px" color="#7F1D1D" />
					</Stack>
					<TextSection>
						<ContentInformationSection>
							<Title>{t('screens.selector.assembly.assemblyLength.alertSection.left.title')}</Title>
							<Message>{t('screens.selector.assembly.assemblyLength.alertSection.left.message')}</Message>
						</ContentInformationSection>
						<ContentInformationSection>
							<Title>{t('screens.selector.assembly.assemblyLength.alertSection.right.title')}</Title>
							<Message>{t('screens.selector.assembly.assemblyLength.alertSection.right.message')}</Message>
						</ContentInformationSection>
					</TextSection>
				</InformationSection>
			</InputZone>
		</Stack>
	)
}

export default AssemblyLength
