import React, { FC } from 'react'
import LabelSubtitle from '../../typo/LabelSubtitle'
import { Select, styled } from '@mui/material'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import SelectOption from './SelectOption'

const SelectionInput = styled(Select, {
		shouldForwardProp: (prop: string) => prop !== 'width'
	}
)<{ width: string }>(
	({ width, theme }) => ({
		width: width,
		border: '0px solid #BCBCBC',
		borderRadius: '6px',
		boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
		color: theme.palette.text.primary,
		fontSize: '13px',
		fontWeight: 400,
		lineHeight: '16px',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		backgroundColor: theme.palette.secondary.main,
		height: '42px'
	})
)

const SelectInput: FC<SelectInputType> = (
	{
		label,
		options,
		itemSelected,
		setItemSelected,
		width
	}
) => {
	return (
		<>
			<LabelSubtitle color="#262626" marginBottom="4px">{label}</LabelSubtitle>
			<SelectionInput width={width} value={itemSelected} onChange={e => setItemSelected(e.target.value as string)} IconComponent={UnfoldMoreIcon}>
				{options.map((opt: string) => <SelectOption value={opt} key={opt}>{opt}</SelectOption>)}
			</SelectionInput>
		</>
	)
}

export default SelectInput