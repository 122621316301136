import React, { FC } from 'react'
import FittingItemLeftSection from './left/FittingItemLeftSection'
import FittingItemRightSection from './right/FittingItemRightSection'
import HoseImageSection from '../../../../../../image/HoseImageSection'
import ProductImage from '../../../../../../image/ProductImage'
import { LOCAL_STORAGE_KEYS } from '../../../../../../../config/enums'
import { SOURCE_VALUE } from '../../../../../../../modules/selector/utils/constants'

const FittingItem: FC<FittingItemType> = ({ source }) => {

	return (
		<>
			<HoseImageSection>
				<ProductImage image={source === SOURCE_VALUE.FITTING_A ? localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_IMAGE)! : localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_IMAGE)!} />
			</HoseImageSection>
			<FittingItemLeftSection source={source} />
			<FittingItemRightSection source={source} />
		</>
	)
}

export default FittingItem
