import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, styled, Typography } from '@mui/material'
import PartNumber from '../../../../../../../components/selector/PartNumber'
import StatusTypo from '../../../../../../../components/typo/StatusTypo'
import { STATUS_PRODUCT } from '../../../../../../../utils/constants'
import { PartDetailDialog } from '../../../../../../../components/selector/PartDetailDialog'

const HoseLeftInformationContainer = styled(Stack, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ isSelected }) => ({
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		padding: '16px 0',
		gap: '4px',
		alignSelf: 'stretch',
		flexGrow: '1',
		borderRight: `2px solid rgba(${isSelected ? '255, 255, 255' : '0, 0, 0'} , 0.1)`
	})
)

const HoseTitle = styled(Typography, {
		shouldForwardProp: (prop) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ theme, isSelected }) => ({
		fontSize: '14px',
		fontWeight: 600,
		fontFamily: 'Inter',
		lineHeight: '18px',
		color: isSelected ? theme.palette.text.secondary : theme.palette.text.primary,
		fontStyle: 'normal'
	})
)

const HoseItemLeftSection: FC<HoseItemSectionType> = ({ isSelected, hose }) => {
	const { t } = useTranslation()
	return (
		<HoseLeftInformationContainer isSelected={isSelected}>
			<Stack flexDirection="column" alignItems="flex-start" padding="0" gap="4px">
				<HoseTitle isSelected={isSelected}>{hose.series_name}</HoseTitle>
				<StatusTypo isSelected={isSelected}>{t(`${hose.store_part_number ? STATUS_PRODUCT.REGULAR : STATUS_PRODUCT.ON_REQUEST}`)}</StatusTypo>
				<PartNumber isSelected={isSelected} partNumber={t('components.assemblyProgressionPanel.partNumber', { partNumber: hose.part_number })} />
				{
					hose.store_part_number &&
					<PartNumber isSelected={isSelected} partNumber={t('components.assemblyProgressionPanel.storePartNumber', { partNumber: hose.store_part_number })} />
				}
			</Stack>
			<PartDetailDialog partNumber={hose.part_number} seriesName={hose.series_name} isSelected={isSelected} />
		</HoseLeftInformationContainer>
	)
}

export default HoseItemLeftSection
