import React, { FC } from 'react'
import { Stack } from '@mui/material'
import BasicButton from '../../../components/buttons/BasicButton'
import { useTranslation } from 'react-i18next'
import { HomeMessage, HomeTitle } from '../../../components/typo/HomeTypo'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { HOME_LINKS } from '../../selector/utils/constants'

const Actuality: FC = () => {
	const { t } = useTranslation()
	return (
		<Stack flexDirection="row" justifyContent="space-between" alignItems="center" alignSelf="stretch">
			<Stack width="50%">
				<HomeTitle>{t('screens.home.content.actuality.title')}</HomeTitle>
				<HomeMessage>{t('screens.home.content.actuality.message')}</HomeMessage>
			</Stack>
			<Stack alignItems="center" justifyContent="center">
				<BasicButton onClick={() => window.open(HOME_LINKS.ACTUALITY, '_blank')} variant="outlined" startIcon={<ArrowForwardIosIcon />}>{t('screens.home.content.actuality.button')}</BasicButton>
			</Stack>
		</Stack>
	)
}

export default Actuality
