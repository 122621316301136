import React from 'react'
import { useTranslation } from 'react-i18next'
import './HoseDetailBody.css'

const HoseDetailBody = () => {
	const { t } = useTranslation()
	return (
		<div className="modal-hose-detail--body-table">
			<div className="modal-hose-detail--body-column">
				<ul className="modal-hose-detail--body-column-list">
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.minimumBendRadius')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.hoseIDSize')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.division')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.application')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.productType')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.maximumOperatingPressure')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.hoseOutsideDiameter')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.designFactor')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.compatibleFitting')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.minmimumOperatingTemperature')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.mateiralsOfConstruction')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.hoseInnerTubeMaterial')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.weight')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
				</ul>
			</div>
			<div className="modal-hose-detail--body-column">
				<ul className="modal-hose-detail--body-column-list">
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.hoseCoverType')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.brand')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.industry')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.technology')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.productStyle')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.hoseInsideDiameter')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.minimumBendRadius')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.coverColor')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.maximumOperatingTemperature')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.specificationMet')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.coverMaterial')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.hoseReinforcementMaterial')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
					<li className="modal-hose-detail--body-column--row">
						<span className="modal-hose-detail--body-column--row-tile modal-hose-detail--body-column--row-content">{t('modal.detail.body.media')}</span>
						<span className="modal-hose-detail--body-column--row-value modal-hose-detail--body-column--row-content"></span>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default HoseDetailBody