import React from 'react'
import { styled, Typography } from '@mui/material'

const ProductTitle = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ isSelected }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '18px',
		color: isSelected ? '#FFF' : '#000'
	})
)

export default ProductTitle