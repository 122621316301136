import React from 'react'
import { styled, Typography } from '@mui/material'

const ProductSubtitle = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ isSelected, theme }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '12px',
		lineHeight: '16px',
		color: isSelected ? theme.palette.secondary.main : theme.palette.primary.main
	})
)

export default ProductSubtitle