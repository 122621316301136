import { Stack, styled } from '@mui/material'

const ProductGrid = styled(Stack)(
	({ theme }) => ({
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fill, minmax(21%, 1fr))',
		boxSizing: 'border-box',
		padding: '12px',
		background: theme.palette.assemblyPanel.main,
		borderRadius: '12px',
		borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
		flex: 'none',
		alignSelf: 'stretch',
		flexGrow: 0,
		flexWrap: 'wrap'
	})
)

export default ProductGrid