import { INITIAL_STEP, LOCAL_STORAGE_KEY, STEP_ASSEMBLY } from './constants'
import { AssemblyStepType, AssemblySubStepType, CurrentStepType, StepValuesType } from '../@types/constants'
import { CurrentStepContextType } from '../@types/contextes'
import { LOCAL_STORAGE_KEYS } from '../config/enums'
import { ASSEMBLY_RANK_STEP } from '../modules/selector/utils/fittingsUtils'
import { useCurrentStepContext } from '../modules/selector/utils/utils'
import { TYPE_PRESSURE } from '../modules/selector/utils/constants'
import { t } from 'i18next'

/**
 * Passage à la sous-étape suivante si possible
 * @param currentStep
 */
export const nextSubStep = (currentStep: CurrentStepType): CurrentStepType => {
	const step = STEP_ASSEMBLY.filter(step => currentStep.rank === step.rank)
	if (step && step.length === 1 && currentStep.values) {
		const stepActive = step[0]
		const indexCurrentSubStep = stepActive.substeps.findIndex(subStep => subStep.key === currentStep.subStepKey)
		if (indexCurrentSubStep >= 0 && !currentStep.lastSubStep) {
			console.log(currentStep)
			saveDataInLocalStorage(currentStep.values)

			for (let i = 0; i < stepActive.substeps.length; i++) {
				let substep = stepActive.substeps[i]

				if (!subStepIsComplete(substep)) {
					const indexNextSubStep = stepActive.substeps.findIndex(subStep => subStep.key === substep.key)
					return {
						rank: currentStep.rank,
						subStepKey: stepActive.substeps[indexNextSubStep].key,
						lastSubStep: indexNextSubStep === stepActive.substeps.length - 1,
						firstSubStep: indexNextSubStep === 0,
						values: undefined
					}
				}
			}
		}
	}
	return currentStep
}

/**
 * Passage à la sous-étape précédente si possible
 * @param currentStep
 */
export const previousSubStep = (currentStep: CurrentStepType): CurrentStepType => {
	const step = STEP_ASSEMBLY.filter(step => currentStep.rank === step.rank)
	if (step && step.length === 1) {
		const stepActive = step[0]
		const indexCurrentSubStep = stepActive.substeps.findIndex(subStep => subStep.key === currentStep.subStepKey)
		if (indexCurrentSubStep > 0 && !currentStep.firstSubStep) {
			// suppression des données stockées dans le localstorage de l'ancienne étape
			const previousSubstep = stepActive.substeps[indexCurrentSubStep - 1]
			previousSubstep.data.forEach(substep => {
				localStorage.removeItem(substep.localStorageKey)
			})

			return {
				rank: currentStep.rank,
				subStepKey: stepActive.substeps[indexCurrentSubStep - 1].key,
				lastSubStep: indexCurrentSubStep - 1 === stepActive.substeps.length - 1,
				firstSubStep: indexCurrentSubStep - 1 === 0,
				values: undefined
			}
		}
	}
	return currentStep
}

/**
 * Passage à l'étape principale suivante
 * @param currentStep
 */
export const nextStep = (currentStep: CurrentStepType): CurrentStepType => {
	const step = STEP_ASSEMBLY.filter(step => currentStep.rank === step.rank)
	if (step && step.length === 1 && currentStep.values) {
		const stepActive = step[0]
		saveDataInLocalStorage(currentStep.values)
		if (stepIsDone(stepActive)) {
			let nextStep = STEP_ASSEMBLY.filter(step => currentStep.rank + 1 === step.rank)
			while (stepIsDone(nextStep[0]) && nextStep[0].rank < ASSEMBLY_RANK_STEP) {
				nextStep = STEP_ASSEMBLY.filter(step => nextStep[0].rank + 1 === step.rank)
			}
			const nextStepActive = nextStep[0]
			return {
				rank: nextStepActive.rank,
				subStepKey: nextStepActive.substeps[0].key,
				lastSubStep: nextStepActive.substeps.length === 1,
				firstSubStep: true,
				values: undefined
			}
		}
	}
	return currentStep
}

/**
 * Passage à l'étape principale précédente
 * @param currentStep
 */
export const previousStep = (currentStep: CurrentStepType): CurrentStepType => {
	const step = STEP_ASSEMBLY.filter(step => step.rank === currentStep.rank - 1)
	if (step && step.length === 1) {
		const stepActive = step[0]
		return {
			rank: stepActive.rank,
			subStepKey: stepActive.substeps[stepActive.substeps.length - 1].key,
			lastSubStep: true,
			firstSubStep: stepActive.substeps.length - 1 === 0,
			values: undefined
		}
	}
	return currentStep
}

/**
 * Vérifie si l'ensemble des variables présentes dans le local storage ont bien été défini afin de pouvoir valider une étape
 * @param substep - sous étape à vérifier
 */
export const subStepIsComplete = (substep: AssemblySubStepType) => {
	let subStepDone = true
	for (let index in substep.data) {
		if (!substep.data[index].optional && !localStorage.hasOwnProperty(substep.data[index].localStorageKey)) {
			subStepDone = false
		}
	}
	return subStepDone
}

/**
 * Enregistrement des valeurs d'une étape dans le local storages
 * @param values - liste des valeurs à sauvegarder
 */
const saveDataInLocalStorage = (values: Array<StepValuesType>) => {
	values.forEach(stepData => {
		if (stepData) {
			if (stepData.value !== null) {
				localStorage.setItem(stepData.localStorageKey, stepData.value)
			}
		}
	})
}

/**
 * Reset la totalité ou une partie de la sélection
 * @param rankStep
 */
export const resetStep = (rankStep: number): CurrentStepType => {
	const step = STEP_ASSEMBLY.filter(it => it.rank === rankStep)[0]
	const newCurrentStep = {
		rank: rankStep,
		subStepKey: step.substeps[0].key,
		lastSubStep: false,
		firstSubStep: true,
		values: undefined
	}
	switch (rankStep) {
		case 1:
			clearLocalStorage()
			return INITIAL_STEP
		case 2:
			clearStep(rankStep)
			clearStep(ASSEMBLY_RANK_STEP)
			return newCurrentStep
		case 3:
			clearStep(rankStep)
			clearStep(ASSEMBLY_RANK_STEP)
			return newCurrentStep
		case 4:
			clearStep(rankStep)
			return newCurrentStep
		default:
			return useCurrentStepContext().currentStep
	}
}

/**
 * Vérifie qu'une étape possède bien l'ensemble des données dans le local storage
 * @param step
 */
export const stepIsDone = (step: AssemblyStepType): boolean => {
	let isDone = true
	if (step) {
		step.substeps.forEach(substep => {
			substep.data.forEach(it => {
				if (!localStorage.getItem(it.localStorageKey) && !it.optional) {
					isDone = false
				}
			})
		})
	}
	return isDone
}

export const getNextSubstep = (step: AssemblyStepType): string | null => {
	let isDone: string | null = null
	if (step) {
		step.substeps.forEach(substep => {
			substep.data.forEach(it => {
				if (!localStorage.getItem(it.localStorageKey) && !it.optional && isDone === null) {
					isDone = it.localStorageKey
				}
			})
		})
	}
	return isDone
}

/**
 * Affichage des informations sélectionné dans l'Assembly Progression Panel
 * @param source - clé de l'étape qui appel la fonction
 * @param t - fonction de traduction de i18n
 */
export const seeSelection = (source: string, t: any): string | null => {
	switch (source) {
		case 'transportedFluid':
			return t(`data.medias.${localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MEDIA)}`)
		case 'indiseDiameter':
			return t('global.unit.diameter.mm', { diameter: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_DIAMETER_MM) })
		case 'maxWorkingPressure':
			if (localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_TYPE_PRESSURE_SELECTED) === TYPE_PRESSURE.VACUUM) {
				return t('global.unit.pressure.vacuum', {
					vacuum: numberWithSpaces(Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_VACUUM_PRESSURE)))
				})
			}
			return t('global.unit.pressure.bar', {
				pressure: numberWithSpaces(Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE)))
			})
		case 'workingTemperature':
			return t('global.unit.temperature.plageDegC', {
				minDeg: numberWithSpaces(Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MIN_WORKING_TEMPERATURE))),
				maxDeg: numberWithSpaces(Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_TEMPERATURE)))
			})
		case 'hoseSelection':
			return ''
		case 'fAMaterial':
			return localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_MATERIAL)
		case 'fAConfiguration':
			return concatFittingInformation(localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_GENDER)!, localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SHAPE)!, localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SPECIFIC_DESCRIPTION))
		case 'fASize':
			return ''
		case 'fBMaterial':
			return localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_MATERIAL)
		case 'fBConfiguration':
			return concatFittingInformation(localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_GENDER)!, localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_SHAPE)!, localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_SPECIFIC_DESCRIPTION))
		case 'fBSize':
			return ''
		case 'assemblyLength':
			return t('global.unit.diameter.cm', { diameter: (numberWithSpaces(Number(localStorage.getItem(LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH)) / 10)) })
		case 'fittingsAngle':
			return ''
		default:
			return ''
	}
}

/**
 * Extrait les données utiles à l'affiche des tuiles de l'Assembly Progression Panel lorsqu'une étape est terminée
 * @param step - étape pour laquelle on extrait les données
 */
export const extractDataUtilsForAssemblyProgressionPanel = (step: AssemblyStepType): HoseDataUtilsType | null => {
	switch (step.rank) {
		case 1:
			return {
				status: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_STATUS),
				partNumber: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_PART_NUMBER),
				storePartNumber: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_STORE_PART_NUMBER),
				image: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_IMAGE),
				seriesName: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_SERIES_NAME)
			}
		case 2:
			return {
				status: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STATUS),
				partNumber: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_PART_NUMBER),
				storePartNumber: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STORE_PART_NUMBER),
				image: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_IMAGE),
				seriesName: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SERIES_NAME)
			}
		case 3:
			return {
				status: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_STATUS),
				partNumber: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_PART_NUMBER),
				storePartNumber: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_STORE_PART_NUMBER),
				image: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_IMAGE),
				seriesName: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_SERIES_NAME)
			}
	}
	return null
}

/**
 * Duplique fitting A dans fitting B
 * @param context - context permettant de gérer l'étape courante
 */
export const duplicateFittingA = (context: CurrentStepContextType) => {
	const currentStep = context.currentStep
	context.updateCurrentStep({
		rank: currentStep.rank,
		subStepKey: currentStep.subStepKey,
		lastSubStep: currentStep.lastSubStep,
		firstSubStep: currentStep.firstSubStep,
		values: [
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_PART_NUMBER,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_PART_NUMBER)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_SERIES_NAME,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SERIES_NAME)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_STATUS,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STATUS)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_IMAGE,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_IMAGE)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_MATERIAL,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_MATERIAL)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_STANDARD,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STANDARD)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_GENDER,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_GENDER)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_SHAPE,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SHAPE)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_SPECIFIC_DESCRIPTION,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SPECIFIC_DESCRIPTION)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_SIZE,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SIZE)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_CONNEXION_LENGTH_MM,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_CONNEXION_LENGTH_MM)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_MAX_WORKING_PRESSURE,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_MAX_WORKING_PRESSURE)
			},
			{
				localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_STORE_PART_NUMBER,
				value: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STORE_PART_NUMBER)
			}
		]
	})
}


/**
 * Concaténation des informations d'un fitting
 * @param gender
 * @param shape
 * @param specificDescription
 */
export const concatFittingInformation = (gender: string, shape: string, specificDescription: string | null) => {
	let fittingInformation = `${gender}, ${shape}`
	if (specificDescription !== null) {
		fittingInformation += `, ${specificDescription}`
	}
	return fittingInformation
}

/**
 * Concaténation des informations d'un fitting pour les afficher sur l'écran de Recap
 * @param standard
 * @param gender
 * @param shape
 * @param specificDescription
 */
export const concatFittingInformationForRecap = (standard: string, gender: string, shape: string, specificDescription: string | null) => {
	let fittingInformation = `${standard} / ${gender} / ${shape}`
	if (specificDescription !== null) {
		fittingInformation += ` / ${specificDescription}`
	}
	return fittingInformation
}

/**
 * Extrait uniquement les entrées uniques du tableau d'objet en fonction du tableau de props
 * @param props
 * @param arrInp
 */
export const getUniqArrBy = (props: Array<string> = [], arrInp: Array<any> = []): Array<any> => {
	return Object.values(arrInp.reduce((res, item, currentIndex) => {

		const keyComb = props.reduce((resultat, prop) => [...resultat, `${item[prop]}`], []).join('-')

		if (res[keyComb] === undefined) {
			res[keyComb] = item
		}
		return res

	}, {}))
}

/**
 * Supprime dans le local storage les informations liées à cette étape
 * @param rank
 */
export const clearStep = (rank: number) => {
	const substepsData = STEP_ASSEMBLY.filter(step => step.rank === rank)[0].substeps.map(substep => substep.data)
	substepsData.forEach(substep => substep.forEach(data => localStorage.removeItem(data.localStorageKey)))
}

/**
 * Extraction des valeurs de l'inside diameter
 */
export const getInsideDiameter = () => {
	const diameterMm = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_DIAMETER_MM)
	const diameterInch = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_DIAMETER_INCH)
	const diameterSize = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_DIAMETER_SIZE)

	if (diameterMm && diameterInch) {
		return {
			inside_diameter_mm: Number(diameterMm),
			inside_diameter_inch: diameterInch,
			inside_diameter_size: diameterSize
		}
	}
	return null
}

/**
 * Extraction de la valeur la plus faible de la pressure vacuum
 * @param hoses
 */
export const getMinPressureVacuum = (hoses: Array<HoseType>): Number => {
	return Math.min(...hoses.map(o => Number(o.vacuum_pressure_bar)))
}

/**
 * Extraction de la valeur de la pression minimale
 * @param hoses
 */
export const getMinPressure = (hoses: Array<HoseType>): Number => {
	return Math.min(...hoses.map(o => Number(o.max_working_pressure_bar)))
}

/**
 * Extraction de la valeur du standard
 */
export const extractStandard = (): string => {
	const standard = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_STANDARD)
	if (standard) {
		return standard
	}
	return '-'
}

/**
 * Extraction de la valeur du type
 */
export const extractType = (): string => {
	const type = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_TYPE)
	if (type) {
		return type
	}
	return '-'
}

/**
 * Tri les hoses dans l'ordre croissant par rapport à la propriété Max working temperature
 * @param hoseA
 * @param hoseB
 */
export const sortHose = (hoseA: HoseType, hoseB: HoseType): number => {
	if (hoseA.max_working_pressure_bar > hoseB.max_working_pressure_bar) {
		return 1
	}
	if (hoseA.max_working_pressure_bar < hoseB.max_working_pressure_bar) {
		return -1
	}
	return 0
}

/**
 * Tri les valeurs d'inside diameters dans l'ordre croissant par rapport à la valeur en mm
 * @param insideDiameter1
 * @param insideDiameter2
 */
export const sortInsideDiameters = (insideDiameter1: DiametersType, insideDiameter2: DiametersType): number => {
	if (insideDiameter1.inside_diameter_mm > insideDiameter2.inside_diameter_mm) {
		return 1
	}
	if (insideDiameter1.inside_diameter_mm < insideDiameter2.inside_diameter_mm) {
		return -1
	}
	return 0
}

export const numberWithSpaces = (x: number) => {
	let parts = x.toString().split(".")
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
	return parts.join(",")
}

export const clearLocalStorage = () => {
	const language = localStorage.getItem(LOCAL_STORAGE_KEY.SELECTED_LANGUAGE)
	localStorage.clear()
	localStorage.setItem(LOCAL_STORAGE_KEY.SELECTED_LANGUAGE, language as string)
}
