import React, { FC, useContext, useMemo } from 'react'
import { Stack, styled } from '@mui/material'
import { CurrentStepContext } from '../../utils/contextes/CurrentStepContext'
import { stepIsDone } from '../../utils/selectorUtils'
import ProgressCardItem from './item/basic/ProgressCardItem'
import ProgressDoneCardItem from './item/done/ProgressDoneCardItem'
import ProgressCardHeader from './ProgressCardHeader'

const ProgressCardContainer = styled(Stack, {
		shouldForwardProp: (prop: string) => prop !== 'disable'
	}
)<{ disable: boolean }>(
	({ disable, theme }) => ({
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '16px',
		gap: '12px',
		backgroundColor: disable ? theme.palette.assemblyPanel.card.disable : theme.palette.assemblyPanel.card.active,
		border: '1px solid rgba(0, 0, 0, 0.1)',
		boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
		borderRadius: '12px',
		width: '100%'
	})
)

const ProgressCard: FC<ProgressCardType> = ({ step }) => {
	const context = useContext(CurrentStepContext)
	const done: boolean = useMemo(() => stepIsDone(step), [step, context.currentStep])
	const disabled: boolean = useMemo(() => step.rank != context.currentStep.rank, [step, context.currentStep, done])

	const body = useMemo(() => {
		if (done) {
			return <ProgressDoneCardItem step={step} />
		} else {
			return step.substeps.filter((it: { leftMenu: any }) => it.leftMenu).map((subst: any) => <ProgressCardItem substep={subst} key={subst.title} />)
		}
	}, [step, done])

	return (
		<ProgressCardContainer disable={disabled && !done}>
			<ProgressCardHeader step={step} disabled={disabled} done={done} />
			<Stack flexDirection="column" alignItems="flex-start" flexGrow="1" width="100%">
				{body}
			</Stack>
		</ProgressCardContainer>
	)
}

export default ProgressCard