import React, { FC } from 'react'
import BasicButton from '../../../components/buttons/BasicButton'
import { Stack } from '@mui/material'
import { HomeMessageCard, HomeTitle } from '../../../components/typo/HomeTypo'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

type CustomAdType = {
	title: string,
	message: string,
	labelButton: string,
	link?: string
}

const CustomAd: FC<CustomAdType> = ({ title, message, labelButton, link }) => (
	<>
		<Stack alignItems="center" justifyContent="center">
			<HomeTitle padding="0">{title}</HomeTitle>
		</Stack>
		<HomeMessageCard>{message}</HomeMessageCard>
		<Stack alignItems="center" justifyContent="center">
			<BasicButton variant="outlined" startIcon={<ArrowForwardIosIcon />} href={link}>{labelButton}</BasicButton>
		</Stack>
	</>
)

export default CustomAd
