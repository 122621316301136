import { styled, Typography } from '@mui/material'

const SliderValue = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'textPosition'
	}
)<{ textPosition: 'right' | 'left' | 'center' }>(
	({ theme, textPosition }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '18px',
		color: theme.palette.primary.main,
		width: '100px',
		paddingLeft: '0 32px',
		margin: '0 32px',
		textAlign: textPosition
	})
)

export default SliderValue