import React, { createContext, FC, useState } from 'react'
import { extractDistributorToken } from '../utils'
import {LOCAL_STORAGE_KEY} from "../constants";

type DistributorContext = {
	token: string | null,
	updateToken: (newToken: string | null) => void,
	storeInformation: StoreType | null | undefined,
	setStoreInformation: (newStoreInformation: StoreType | null | undefined) => void
}

type DistributorContextProviderType = {
	children: any
}

export const DistributorContext = createContext<DistributorContext>({
	token: extractDistributorToken(),
	updateToken(): void {},
	storeInformation: null,
	setStoreInformation(): void {}
})

export const DistributorContextProvider: FC<DistributorContextProviderType> = ({ children }) => {
	const [token, setToken] = useState<string | null>(localStorage.getItem(LOCAL_STORAGE_KEY.STORE_TOKEN))
	const [storeInformation, setStoreInformation] = useState<StoreType | null | undefined>(null)

	const updateToken = (newToken: string | null) => {
		if (newToken) {
			setToken(newToken)
			localStorage.setItem(LOCAL_STORAGE_KEY.STORE_TOKEN, newToken)
		}
	}


	return <DistributorContext.Provider value={{ token, updateToken, storeInformation, setStoreInformation }}>
		{children}
	</DistributorContext.Provider>
}
