import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, styled, Typography } from '@mui/material'
import SliderSingleValue from '../../../../components/form/slider/SliderSingleValue'
import { FITTINGS_ANGLE_SLIDER, FORMAT_VALUE_LABEL_SLIDER } from '../../../../utils/constants'
import { useCurrentStepContext } from '../../utils/utils'
import { LOCAL_STORAGE_KEYS } from '../../../../config/enums'
import fittingsAngleImg from '../../../../assets/img/selector/graduation_image.png'
import fittingB from '../../../../assets/img/selector/fitting_b.png'

const Title = styled(Typography)(
	({ theme }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '28px',
		color: theme.palette.text.primary
	})
)

const FittingImage = styled('img')({
	position: 'absolute',
	height: '340px',
	width: '340px',
	top: 0,
	left: 0
})

const FittingImageSuperpose = styled('img')({
	position: 'absolute',
	height: '280px',
	width: '280px',
	top: 30,
	left: 30
})

const SliderSection = styled(Stack)(
	({ theme }) => ({
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		padding: '72px 56px',
		gap: '10px',
		backgroundColor: theme.palette.assemblyPanel.main,
		borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
		borderRadius: '12px',
		alignSelf: 'stretch'
	})
)

const FittingsAngle = () => {
	const { t } = useTranslation()
	const { currentStep, updateCurrentStep } = useCurrentStepContext()
	const [angleValue, setAngleValue] = useState<number | number[]>(FITTINGS_ANGLE_SLIDER.defaultValue)

	useEffect(() => {
		updateCurrentStep({
			rank: currentStep.rank,
			subStepKey: currentStep.subStepKey,
			lastSubStep: currentStep.lastSubStep,
			firstSubStep: currentStep.firstSubStep,
			values: [
				{
					localStorageKey: LOCAL_STORAGE_KEYS.FITTINGS_ANGLE,
					value: angleValue
				}
			]
		})
	}, [angleValue])

	return (
		<Stack flexDirection="column" alignItems="center" alignSelf="stretch" marginTop="24px">
			<Title>{t('screens.selector.assembly.fittingsAngle.title')}</Title>
			<Stack marginTop="24px" position="relative" height="380px" width="340px">
				<FittingImage src={fittingsAngleImg} />
				<FittingImageSuperpose
					src={fittingB}
					style={{ transform: `rotate(${Number(angleValue)-90}deg)`, transition: 'transform 150ms ease' }}
				/>
			</Stack>
			<SliderSection>
				<SliderSingleValue
					min={FITTINGS_ANGLE_SLIDER.min}
					max={FITTINGS_ANGLE_SLIDER.max}
					step={FITTINGS_ANGLE_SLIDER.step}
					defaultValue={FITTINGS_ANGLE_SLIDER.defaultValue}
					setSliderValue={setAngleValue}
					leftLabel={t('global.unit.angle.deg', {deg: FITTINGS_ANGLE_SLIDER.min})}
					rightLabel={t('global.unit.angle.deg', {deg: FITTINGS_ANGLE_SLIDER.max})}
					format={FORMAT_VALUE_LABEL_SLIDER.ANGLE}
				/>
			</SliderSection>
		</Stack>
	)
}

export default FittingsAngle