import { styled } from '@mui/material'
import pageBackground from '../../assets/img/global/background.png'

const PageContainer = styled('section')<{ direction: any, alignement: string }>(
	({ direction, alignement }) => ({
		display: 'flex',
		flexDirection: direction,
		flexGrow: 1,
		backgroundImage: `url(${pageBackground})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundAttachment: 'fixed',
		backgroundSize: 'cover',
		alignItems: alignement,
		minHeight: window.innerHeight - 90
	})
)

export default PageContainer
