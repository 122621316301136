import React from 'react'
import { styled, Typography } from '@mui/material'

const StatusTypo = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected?: boolean }>(
	({ isSelected }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '12px',
		lineHeight: '16px',
		color: isSelected ? '#BCBCBC' : '#828282',
		marginBottom: '4px'
	})
)
export default StatusTypo