import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import Slider from '@mui/material/Slider'
import SliderValue from './SliderValue'

const CustomSliderDoubleValue = styled(Slider)`
  .MuiSlider-track {
    background: #000000;
    border-color: #000000;
    height: 8px;
  }

  .MuiSlider-rail {
    background: #E4E4E4;
    height: 8px;
  }

  .MuiSlider-thumb {
    color: #FFFFFF;
    border: 5px solid #000000;
    height: 24px;
    width: 24px;
  }

  .MuiSlider-valueLabel {
    color: #FFFFFF;
    background: #000000;
    border-radius: 6px;
  }
`

const SliderDoubleValue = (
	{
		min,
		max,
		doubleValue,
		step,
		setSliderDoubleValue
	}: SliderDoubleValueType
) => {
	const { t } = useTranslation()

	return (
		<>
			<SliderValue textPosition="right">{t('global.unit.temperature.degC', { degrees: min })}</SliderValue>
			<CustomSliderDoubleValue
				valueLabelDisplay="on"
				defaultValue={min}
				value={doubleValue}
				valueLabelFormat={value => t('global.unit.temperature.degC', {degrees: value})}
				min={min}
				max={max}
				step={step}
				onChange={(event, value: number[]) => {
					setSliderDoubleValue(value)
				}}
				disableSwap
			/>
			<SliderValue textPosition="left">{t('global.unit.temperature.degC', { degrees: max })}</SliderValue>
		</>
	)
}

export default SliderDoubleValue