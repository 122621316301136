import React, { FC } from 'react'
import { CircularProgress, Stack } from '@mui/material'

const Loader: FC = () => (
	<Stack alignItems="center" justifyContent="center" alignSelf="stretch">
		<CircularProgress />
	</Stack>
)

export default Loader
