import { styled, Typography } from '@mui/material'

const PageTitle = styled(Typography)(
	({theme}) => ({
		paddingTop: '100px',
		fontFamily: 'Pragmatica',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '96px',
		lineHeight: '112px',
		color: theme.palette.text.primary
	})
)

export default PageTitle