import { Stack, styled } from '@mui/material'

const SelectorSliderZone = styled(Stack)(
	({ theme }) => ({
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '24px',
		gap: '10px',
		backgroundColor: theme.palette.assemblyPanel.main,
		borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
		borderRadius: '12px',
		flex: 'none',
		alignSelf: 'stretch',
		flexGrow: 0
	})
)

export default SelectorSliderZone