import React from 'react';
import { Button, styled } from '@mui/material'

const BasicButton = styled(Button)({
	padding: '10px 42px',
	textTransform: 'unset',
	borderRadius: '6px',
	fontFamily: 'Inter',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '16px'
})

export default BasicButton
