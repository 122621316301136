import React, { FC, useState } from 'react'
import { Snackbar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Alert from '../modals/Alert'

const SnackbarError: FC<SnackbarErrorType> = (
	{
		messageCode,
		isOpen,
		setIsOpen
	}
) => {
	const { t } = useTranslation()

	return (
	  <Snackbar open={isOpen} autoHideDuration={5000} onClose={() => setIsOpen(false)}>
		  <Alert onClose={() => setIsOpen(false)} severity="error">
			  {t(messageCode)}
		  </Alert>
	  </Snackbar>
  )
}

export default SnackbarError
