import React from 'react'
import { styled, Typography } from '@mui/material'

const ErrorLabel = styled(Typography)(
	({ theme }) => ({
		fontFamily: 'Inter',
		fontWeight: 700,
		fontStyle: 'normal',
		fontSize: '18px',
		lineHeight: '22px',
		color: theme.palette.text.primary
	})
)

export default ErrorLabel