import React, { FunctionComponent } from 'react'
import HoseItemLeftSection from './left/HoseItemLeftSection'
import HoseItemRightSection from './right/HoseItemRightSection'
import ProductImage from '../../../../../../components/image/ProductImage'
import HoseImageSection from '../../../../../../components/image/HoseImageSection'

const HoseItem: FunctionComponent<HoseItemType> = (
	{
		hose,
		isSelected
	}
) => {
	return (
		<>
			<HoseImageSection>
				<ProductImage image={hose.image} />
			</HoseImageSection>
			<HoseItemLeftSection hose={hose} isSelected={isSelected} />
			<HoseItemRightSection isSelected={isSelected} hose={hose} />
		</>
	)
}

export default HoseItem
