import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import { CurrentStepContext } from '../../../../utils/contextes/CurrentStepContext'
import { DUPLICATE_FITTING_OPTION } from '../../../../utils/constants'
import { duplicateFittingA } from '../../../../utils/selectorUtils'
import CheckRadioButton from '../../../../components/form/radio/check/CheckRadioButton'

const DuplicateFitting = () => {
	const { t } = useTranslation()
	const context = useContext(CurrentStepContext)

	const [preConfSelected, setPreConfSelected] = useState(DUPLICATE_FITTING_OPTION[0].key)

	useEffect(() => {
		if (preConfSelected === 'duplicate') {
			duplicateFittingA(context)
		} else {
			const currentStep = context.currentStep
			context.updateCurrentStep({
				rank: currentStep.rank,
				subStepKey: currentStep.subStepKey,
				lastSubStep: currentStep.lastSubStep,
				firstSubStep: currentStep.firstSubStep,
				values: [
					{
						localStorageKey: "",
						value: ""
					}
				]
			})
		}
	}, [preConfSelected])

	return (
		<Stack flexDirection="column" alignItems="flex-start" alignSelf="stretch">
			{DUPLICATE_FITTING_OPTION.map(preConf => (
				<CheckRadioButton key={preConf.key} value={preConf.key} itemSelected={preConfSelected} setSelection={setPreConfSelected}>
					<span>{t(preConf.label)}</span>
				</CheckRadioButton>
			))}
		</Stack>
	)
}

export default DuplicateFitting