import {CurrentStepType} from "../@types/constants";
import {INITIAL_STEP, STEP_ASSEMBLY} from "./constants";
import {stepIsDone, subStepIsComplete} from "./selectorUtils";

/**
 * Extrait le token présent dans l'url
 */
export const extractDistributorToken = (): string | null => {
	const queryParams = new URLSearchParams(window.location.search)
	return queryParams.get('token')
}

/**
 * Récupération de l'étape courante
 */
export const refreshContext = (): CurrentStepType => {
	for (let step of STEP_ASSEMBLY) {
		if (!stepIsDone(step)) {
			for (let substep of step.substeps) {
				if (!subStepIsComplete(substep)) {
					const indexNextSubStep = step.substeps.findIndex(subStep => subStep.key === substep.key)
					return {
						rank: step.rank,
						subStepKey: substep.key,
						lastSubStep: indexNextSubStep === step.substeps.length - 1,
						firstSubStep: indexNextSubStep === 0,
						values: undefined
					}
				}
			}
		}
	}
	return INITIAL_STEP
}
