import React, { useContext, useEffect, useMemo, useState } from 'react'
import ProductGridSelector from '../../../../components/form/grid/product/ProductGridSelector'
import { useTranslation } from 'react-i18next'
import SliderDoubleValue from '../../../../components/form/slider/SliderDoubleValue'
import SliderZone from '../../../../components/form/slider/SliderZone'
import SelectorSliderZone from '../SelectorSliderZone'
import LabelTitle from '../../../../components/typo/LabelTitle'
import { LOCAL_STORAGE_KEYS } from '../../../../config/enums'
import BasicError from '../../../../components/errors/BasicError'
import { getHosesWithFilter } from '../../services/hosesApi'
import Loader from '../../../../components/global/Loader'
import { extractMaxWorkingTemperature, extractMinWorkingTemperature, generateHoseFiltersForApi, useCurrentStepContext } from '../../utils/utils'
import { STEP_SLIDER } from '../../utils/constants'
import {DEFAULT_SLIDER_VALUE_WORKING_TEMPERATURE, STATUS_PRODUCT} from '../../../../utils/constants'
import { SnackContext } from '../../../../utils/contextes/SnackContext'
import { DistributorContext } from '../../../../utils/contextes/DistributorContext';

const WorkingTemperature = () => {
	const { t } = useTranslation()
	const { currentStep, updateCurrentStep } = useCurrentStepContext()

	const [doubleSliderValue, setSliderDoubleValue] = useState<number[]>([DEFAULT_SLIDER_VALUE_WORKING_TEMPERATURE.min, DEFAULT_SLIDER_VALUE_WORKING_TEMPERATURE.max])
	const [productSelected, setProductSelected] = useState<HoseType | null>(null)
	const [products, setProducts] = useState<Array<HoseType>>([])
	const [productsDisplayed, setProductsDisplayed] = useState<Array<HoseType>>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const { setVisibilitySnackbar } = useContext(SnackContext)
	const { token } = useContext(DistributorContext)

	const minWorkingTemperature = useMemo(() => extractMinWorkingTemperature(products), [products])
	const maxWorkingTemperature = useMemo(() => extractMaxWorkingTemperature(products), [products])

	useEffect(() => {
		getHosesWithFilter(generateHoseFiltersForApi(token!!))
			.then((hoses: Array<HoseType>) => {
				setProducts(hoses)
				setProductsDisplayed(hoses)
			})
			.catch(() => setVisibilitySnackbar(true))
			.finally(() => setIsLoading(false))
	}, [])

	useEffect(() => {
		setSliderDoubleValue([DEFAULT_SLIDER_VALUE_WORKING_TEMPERATURE.min, DEFAULT_SLIDER_VALUE_WORKING_TEMPERATURE.max])
	}, [products])


	useEffect(() => {
		if (productSelected) {
			updateCurrentStep({
				rank: currentStep.rank,
				subStepKey: currentStep.subStepKey,
				lastSubStep: currentStep.lastSubStep,
				firstSubStep: currentStep.firstSubStep,
				values: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_VACUUM_PRESSURE,
						value: productSelected.max_working_pressure_bar
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_VACUUM_PRESSURE,
						value: productSelected.vacuum_pressure_bar
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MIN_WORKING_TEMPERATURE,
						value: productSelected.min_working_temperature_c
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_TEMPERATURE,
						value: productSelected.max_working_temperatures.filter(it => it.media_code === localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MEDIA)!)[0].max_working_temperature
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_PART_NUMBER,
						value: productSelected.part_number
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_STORE_PART_NUMBER,
						value: productSelected.store_part_number
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_IMAGE,
						value: productSelected.image
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_SERIES_NAME,
						value: productSelected.series_name
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_STATUS,
						value: productSelected.store_part_number ? STATUS_PRODUCT.REGULAR : STATUS_PRODUCT.ON_REQUEST
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_COLOR,
						value: productSelected.color
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_ABRASION_RESISTANCE,
						value: productSelected.abrasion_resistance
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MIN_BENDING_RADIUS_MM,
						value: productSelected.min_bending_radius_mm
					}
				]
			})
		} else {
			updateCurrentStep({
				rank: currentStep.rank,
				subStepKey: currentStep.subStepKey,
				lastSubStep: currentStep.lastSubStep,
				firstSubStep: currentStep.firstSubStep,
				values: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MIN_WORKING_TEMPERATURE,
						value: doubleSliderValue[0]
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_TEMPERATURE,
						value: doubleSliderValue[1]
					}
				]
			})
		}

		if (products && products.length > 0 && doubleSliderValue && doubleSliderValue.length === 2) {
			setProductsDisplayed(products.filter(product => Number(product.min_working_temperature_c) <= Number(doubleSliderValue[0]) && Number(product.max_working_temperatures.filter(it => it.media_code === localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MEDIA))[0].max_working_temperature) >= Number(doubleSliderValue[1])))
		}
	}, [doubleSliderValue, productSelected])

	return isLoading ? (<Loader />) : (
		<>
			<LabelTitle padding="24px 0">{t('screens.selector.hose.workingTemperature.configureTitle')}</LabelTitle>
			<SelectorSliderZone>
				<SliderZone>
					<SliderDoubleValue
						min={minWorkingTemperature}
						max={maxWorkingTemperature}
						step={STEP_SLIDER}
						doubleValue={doubleSliderValue}
						setSliderDoubleValue={setSliderDoubleValue}
					/>
				</SliderZone>
			</SelectorSliderZone>
			<LabelTitle padding="24px 0">{t('screens.selector.hose.maxWorkingPressure.matchingTitle')}</LabelTitle>
			{(productsDisplayed && productsDisplayed.length > 0) ? (
				<ProductGridSelector productSelected={productSelected} setProductSelected={setProductSelected} products={productsDisplayed} />
			) : (
				<BasicError message={t('errors.noData.hoses')} />
			)}
		</>
	)
}

export default WorkingTemperature
