import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import BasicButton from '../../../components/buttons/BasicButton'
import { Stack } from '@mui/material'
import { HomeMessageCard, HomeTitle } from '../../../components/typo/HomeTypo'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { HOME_LINKS } from '../../selector/utils/constants'

const OurIndustries: FC = () => {
	const { t } = useTranslation()
	return (
		<>
			<Stack alignItems="center" justifyContent="center">
				<HomeTitle padding="0">{t('screens.home.content.ourIndustries.title')}</HomeTitle>
			</Stack>
			<HomeMessageCard>{t('screens.home.content.ourIndustries.message')}</HomeMessageCard>
			<Stack alignItems="center" justifyContent="center">
				<BasicButton onClick={() => window.open(HOME_LINKS.OUR_INDUSTRIES, '_blank')} variant="outlined" endIcon={<ArrowForwardIosIcon />}>{t('screens.home.content.ourIndustries.button')}</BasicButton>
			</Stack>
		</>
	)
}

export default OurIndustries
