import React, { FC, useContext } from 'react'
import { Stack, styled, Typography } from '@mui/material'
import { CurrentStepContext } from '../../../../../../../utils/contextes/CurrentStepContext'

type HoseSpecificityRowType = {
	isSelected: boolean,
	label: string,
	value: string,
	icon?: JSX.Element
}

const LabelSpecificity = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ isSelected, theme }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '12px',
		lineHeight: '18px',
		color: isSelected ? theme.palette.assemblyPanel.card.disable : '#828282'
	})
)

const LabelValue = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ theme, isSelected }) => ({
		fontSize: '12px',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: '18px',
		color: isSelected ? theme.palette.text.secondary : theme.palette.text.primary
	})
)

const HoseSpecificityRow: FC<HoseSpecificityRowType> = ({ isSelected, label, value, icon }) => {
	const context = useContext(CurrentStepContext)

	return (
		<Stack flexDirection="row" justifyContent="flex-end" alignItems="center" gap="12px" padding="0">
			<LabelSpecificity isSelected={isSelected}>{label}</LabelSpecificity>
			<LabelValue isSelected={isSelected}>{value}</LabelValue>
			{context.currentStep.subStepKey !== 'recap' && icon}
		</Stack>
	)
}

export default HoseSpecificityRow