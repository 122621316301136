import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, styled } from '@mui/material'
import ColorSelect from '../../../../components/form/select/ColorSelect'
import SelectInput from '../../../../components/form/select/SelectInput'
import { STANDARDS } from '../../utils/constants'
import CheckboxInput from '../../../../components/form/checkbox/CheckboxInput'

const Container = styled(Stack)(
	({ theme }) => ({
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		padding: '24px',
		gap: '10px',
		background: theme.palette.assemblyPanel.main,
		borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
		borderRadius: '12px 12px 0 0',
		alignSelf: 'stretch'
	})
)

const HoseSelectionHeader: FC<HoseHeaderSelectionType> = (
	{
		colorSelected,
		setColorSelected,
		standardSelected,
		setStandardSelected,
		abrasionResistance,
		setAbrasionResistance,
		colors,
		standards
	}
) => {
	const { t } = useTranslation()
	return (
		<Container>
			<Stack flexDirection="column" width="33%">
				<ColorSelect
					label={t('screens.selector.hose.hoseSelection.color')}
					colors={colors}
					colorSelected={colorSelected}
					setColorSelected={setColorSelected}
				/>
			</Stack>
			<Stack flexDirection="column" width="33%">
				<SelectInput
					label={t('screens.selector.hose.hoseSelection.standard')}
					options={standards}
					itemSelected={standardSelected}
					setItemSelected={setStandardSelected}
					width="100%"
				/>
			</Stack>
			<Stack flexDirection="column" width="33%" alignSelf="stretch" justifyContent="flex-end">
				<CheckboxInput
					checked={abrasionResistance}
					setChecked={setAbrasionResistance}
					label={t('screens.selector.hose.hoseSelection.abrasionResistance')}
				/>
			</Stack>
		</Container>
	)
}

export default HoseSelectionHeader
