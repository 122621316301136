import React, { FC, useState } from 'react'
import { Link, styled } from '@mui/material'
import { resetStep } from '../../utils/selectorUtils'
import { FiRefreshCw } from 'react-icons/fi'
import { useCurrentStepContext } from '../../modules/selector/utils/utils'
import ChangeSelectionModal from '../modals/selection/ChangeSelectionModal';

type RefreshButtonType = {
	rankStep: number
}

const ButtonContainer = styled(Link)(
	({ theme }) => ({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '10px',
		gap: '8px',
		border: `1px solid ${theme.palette.assemblyPanel.card.disable}`,
		borderRadius: '6px',
		cursor: 'pointer'
	})
)

const RefreshButton: FC<RefreshButtonType> = ({ rankStep }) => {
	const { updateCurrentStep } = useCurrentStepContext()
	const [openInfoModal, setOpenInfoModal] = useState<boolean>(false)


	return (
		<>
			<ButtonContainer onClick={() => setOpenInfoModal(true)}>
				<FiRefreshCw size="1rem" />
			</ButtonContainer>
			<ChangeSelectionModal
				open={openInfoModal}
				handleClose={() => setOpenInfoModal(false)}
				handleConfirm={() => updateCurrentStep(resetStep(rankStep))}
			/>
		</>
	)
}

export default RefreshButton
