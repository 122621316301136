import React, { FC, useMemo } from 'react'
import { extractDataUtilsForAssemblyProgressionPanel } from '../../../../utils/selectorUtils'
import { useTranslation } from 'react-i18next'
import { Stack, styled } from '@mui/material'
import ProductImage from '../../../image/ProductImage'
import HoseImageSection from '../../../image/HoseImageSection'
import PartNumber from '../../../selector/PartNumber'
import StatusTypo from '../../../typo/StatusTypo'
import { PartDetailDialog } from '../../../selector/PartDetailDialog'

const SeriesName = styled(Stack)(
	({ theme }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '18px',
		color: theme.palette.text.primary,
		marginBottom: '6px'
	})
)

const ProgressDoneCardItem: FC<ProgressDoneCard> = ({ step }) => {
	const { t } = useTranslation()
	const productDataUtils = useMemo(() => {
		return extractDataUtilsForAssemblyProgressionPanel(step)
	}, [step])

	const isSelectedState = false

	return (
		<Stack flexDirection="row" alignItems="flex-start" gap="24px" alignSelf="stretch">
			<HoseImageSection>
				<ProductImage image={productDataUtils?.image!} />
			</HoseImageSection>
			<Stack flexDirection="column" justifyContent="space-between" alignItems="flex-start" gap="4px" alignSelf="none" flexGrow="1">
				<Stack flexDirection="column" alignItems="flex-start" gap="4" alignSelf="stretch">
					<SeriesName>{productDataUtils?.seriesName}</SeriesName>
					<StatusTypo isSelected={isSelectedState}>{t(productDataUtils?.status!)}</StatusTypo>
					<PartNumber isSelected={isSelectedState} partNumber={t('components.assemblyProgressionPanel.partNumber', { partNumber: productDataUtils?.partNumber! })} />
					{
						productDataUtils?.storePartNumber
						&& <PartNumber isSelected={isSelectedState} partNumber={t('components.assemblyProgressionPanel.storePartNumber', { partNumber: productDataUtils?.storePartNumber! })} />
					}
				</Stack>
				<PartDetailDialog partNumber={productDataUtils?.partNumber as string}
				                  seriesName={productDataUtils?.seriesName as string}
				                  isSelected={isSelectedState}
				/>
			</Stack>
		</Stack>
	)
}

export default ProgressDoneCardItem
