import React from 'react'
import { Stack, styled } from '@mui/material'
import ImageContainer from '../../../../image/ImageContainer'
import ProductImage from '../../../../image/ProductImage'
import ProductTitle from '../../../../typo/product/ProductTitle'
import ProductSubtitle from '../../../../typo/product/ProductSubtitle'

const ProductCardContainer = styled(Stack, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ isSelected }) => ({
		display: 'flex',
		flex: '1 0 21%',
		margin: '5px',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '12px',
		gap: '12px',
		borderRadius: '6px',
		border: isSelected ? '1px solid rgba(0, 0, 0, 0.1)' : 'none',
		background: isSelected ? 'linear-gradient(180deg, #2D2200 0%, #000000 100%)' : 'transparent',
		boxShadow: isSelected ? '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)' : 'none',
		cursor: 'pointer'
	})
)

const ProductCardGrid = ({ pathImg, label, description, active }: ProductCardGridType) => {
	return (
		<ProductCardContainer isSelected={active}>
			<ImageContainer isSelected={active}>
				<ProductImage image={pathImg} />
			</ImageContainer>
			<ProductTitle isSelected={active}>{label}</ProductTitle>
			<ProductSubtitle isSelected={active}>{description}</ProductSubtitle>
		</ProductCardContainer>
	)
}

export default ProductCardGrid