import React, { FC } from 'react'
import { STEP_ASSEMBLY } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'
import { IoCloseOutline } from 'react-icons/io5'
import PrimaryTitle from '../../typo/PrimaryTitle'
import { Link, Stack, styled } from '@mui/material'
import ProgressCard from '../../progress/ProgressCard'

const Container = styled(Stack, {
		shouldForwardProp: (prop: string) => prop !== 'isOpen'
	}
)<{ isOpen: boolean }>(
	({ isOpen, theme }) => ({
		position: 'relative',
		top: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		backgroundColor: theme.palette.assemblyPanel.main,
		alignSelf: 'stretch',
		transition: '1s',
		zIndex: 10,
		left: isOpen ? 0 : '-100%',
		width: isOpen ? '32%' : 0,
		padding: isOpen ? '0 24px' : '0'
	})
)

const ClosePanelButton = styled(Link)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '12px',
	gap: '8px',
	border: '1px solid #E4E4E4',
	borderRadius: '6px',
	cursor: 'pointer'
})

const AssemblyProgressPanel: FC<AssemblyProgressPanelType> = (
	{
		assemblyPanelIsVisible,
		setAssemblyPanelVisibility
	}
) => {
	const { t } = useTranslation()

	return (
		<Container isOpen={assemblyPanelIsVisible}>
			<Stack flexDirection="row" alignItems="center" padding="24px 0" gap="24px" alignSelf="stretch">
				<Stack flexDirection="row" justifyContent="space-between" alignItems="center" padding="0 16px" flexGrow="1">
					<PrimaryTitle>{t('components.assemblyProgressionPanel.title')}</PrimaryTitle>
					<ClosePanelButton underline="none" onClick={() => {
						setAssemblyPanelVisibility(!assemblyPanelIsVisible)
					}}>
						<IoCloseOutline size="1.15rem"/>
					</ClosePanelButton>
				</Stack>
			</Stack>
			<Stack flexDirection="column" alignItems="flex-start" gap="12px" width="100%">
				{STEP_ASSEMBLY.map(step => <ProgressCard step={step} key={step.rank} />)}
			</Stack>
		</Container>
	)
}

export default AssemblyProgressPanel