import React, { useContext, useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { LOCAL_STORAGE_KEYS } from '../../../../config/enums'
import CheckRadioButton from '../../../../components/form/radio/check/CheckRadioButton'
import { getFittingsWithFilters } from '../../services/fittingsApi'
import { generateFittingsFilters, RANK_FITTING_A, sortFittingSize } from '../../utils/fittingsUtils'
import Loader from '../../../../components/global/Loader'
import ErrorLabel from '../../../../components/typo/ErrorLabel'
import { useTranslation } from 'react-i18next'
import { useCurrentStepContext } from '../../utils/utils'
import { STATUS_PRODUCT } from '../../../../utils/constants'
import { SnackContext } from '../../../../utils/contextes/SnackContext'
import FittingInformation from '../../../../components/typo/FittingInformation'

const FittingSize = () => {
	const { t } = useTranslation()
	const { currentStep, updateCurrentStep } = useCurrentStepContext()

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [fittings, setFittings] = useState<Array<FittingType>>([])
	const [sizeSelected, setSizeSelected] = useState<string>()
	const { setVisibilitySnackbar } = useContext(SnackContext)

	useEffect(() => {
		getFittingsWithFilters(generateFittingsFilters(currentStep.rank!))
			.then((data: Array<FittingType>) => setFittings(data.sort(sortFittingSize)))
			.catch(() => setVisibilitySnackbar(true))
			.finally(() => setIsLoading(false))
	}, [])

	useEffect(() => {
		const fitting = fittings.find((it: FittingType) => it.size === sizeSelected)
		if (fitting && sizeSelected) {
			updateCurrentStep({
				rank: currentStep.rank,
				subStepKey: currentStep.subStepKey,
				lastSubStep: currentStep.lastSubStep,
				firstSubStep: currentStep.firstSubStep,
				values: [
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_SIZE : LOCAL_STORAGE_KEYS.FITTING_B_SIZE,
						value: fitting.size
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_PART_NUMBER : LOCAL_STORAGE_KEYS.FITTING_B_PART_NUMBER,
						value: fitting.part_number
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_SERIES_NAME : LOCAL_STORAGE_KEYS.FITTING_B_SERIES_NAME,
						value: fitting.series_name
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_STATUS : LOCAL_STORAGE_KEYS.FITTING_B_STATUS,
						value: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_STORE_PART_NUMBER) ? STATUS_PRODUCT.REGULAR : STATUS_PRODUCT.ON_REQUEST
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_IMAGE : LOCAL_STORAGE_KEYS.FITTING_B_IMAGE,
						value: fitting.image
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_CONNEXION_LENGTH_MM : LOCAL_STORAGE_KEYS.FITTING_B_CONNEXION_LENGTH_MM,
						value: fitting.connexion_length_mm
					}
				]
			})
		}
	}, [sizeSelected, fittings])

	return isLoading ? (
		<Loader />
	) : (
		<Stack flexDirection="column" alignItems="flex-start" alignSelf="stretch">
			{(fittings && fittings.length > 0) ?
				fittings.map((fit: FittingType) => (
					<CheckRadioButton key={fit.size} value={fit.size} itemSelected={sizeSelected} setSelection={setSizeSelected}>
						<FittingInformation isSelected={sizeSelected === fit.size}>{fit.size}</FittingInformation>
					</CheckRadioButton>
				)) : (
					<ErrorLabel>{t('errors.noData.fittings.size')}</ErrorLabel>
				)}
		</Stack>
	)
}

export default FittingSize