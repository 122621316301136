import React from 'react'
import { useTranslation } from 'react-i18next'
import { RiShareBoxFill } from 'react-icons/ri'
import { Button, Stack, styled } from '@mui/material'
import PrimaryTitle from '../typo/PrimaryTitle'
import LabelSubtitle from '../typo/LabelSubtitle'

const Container = styled(Stack)(
	({ theme }) => ({
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '24px',
		margin: '24px',
		gap: '24px',
		backgroundColor: theme.palette.secondary.main,
		boxShadow: '0 100px 80px rgba(165, 109, 0, 0.07),\n' +
			'    0 42px 33px rgba(165, 109, 0, 0.0503198),\n' +
			'    0 22px 18px rgba(165, 109, 0, 0.0417275),\n' +
			'    0 13px 10px rgba(165, 109, 0, 0.035),\n' +
			'    0 7px 5px rgba(165, 109, 0, 0.0282725),\n' +
			'    0 3px 2px rgba(165, 109, 0, 0.0196802)',
		borderRadius: '12px'
	})
)

const CrimpButton = styled(Button)(
	({ theme }) => ({
		padding: '14px 24px',
		gap: '12px',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.main,
		'&:hover': {
			background: theme.palette.primary.main
		}
	})
)

const CrimpSource = () => {
	const { t } = useTranslation()
	return (
		<Container>
			<div>
				<PrimaryTitle>{t('components.crimp.title')}</PrimaryTitle>
				<LabelSubtitle>{t('components.crimp.subtitle')}</LabelSubtitle>
			</div>
			<CrimpButton variant="contained" color="secondary" onClick={() => window.open('https://divapps.parker.com/divapps/hpd/crimpsourcev2/Default.aspx?CC=Eur', '_blank')!.focus()} endIcon={<RiShareBoxFill color="#FFF" size={20} />}>
				{t('components.crimp.btn')}
			</CrimpButton>
		</Container>
	)
}

export default CrimpSource
