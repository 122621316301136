import React, { useContext, useEffect, useState } from 'react'
import CheckRadioButton from '../../../../components/form/radio/check/CheckRadioButton'
import { useTranslation } from 'react-i18next'
import InsideDiameterOption from '../../../../modules/selector/components/hose/InsideDiameterOption'
import { getHosesWithFilter } from '../../services/hosesApi'
import { LOCAL_STORAGE_KEYS } from '../../../../config/enums'
import { getInsideDiameter, getUniqArrBy, sortInsideDiameters } from '../../../../utils/selectorUtils'
import Loader from '../../../../components/global/Loader'
import { extractInsideDiameters, generateHoseFiltersForApi, useCurrentStepContext } from '../../utils/utils'
import { SnackContext } from '../../../../utils/contextes/SnackContext'
import BasicError from '../../../../components/errors/BasicError'
import { DistributorContext } from '../../../../utils/contextes/DistributorContext';

const InsideDiameter = () => {
	const { t } = useTranslation()
	const { currentStep, updateCurrentStep } = useCurrentStepContext()

	const [diameters, setDiameters] = useState<Array<DiametersType>>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [diameterSelected, setDiameterSelected] = useState<DiametersType | null>(getInsideDiameter())
	const { setVisibilitySnackbar } = useContext(SnackContext)
	const { token } = useContext(DistributorContext)

	useEffect(() => {
		getHosesWithFilter(generateHoseFiltersForApi(token!!))
			.then((data: Array<HoseType>) => {
				const insideDiameters = extractInsideDiameters(getUniqArrBy(['inside_diameter_mm', 'inside_diameter_inch', 'inside_diameter_size'], data))
				setDiameters(insideDiameters.sort(sortInsideDiameters))
			})
			.catch(() => setVisibilitySnackbar(true))
			.finally(() => setIsLoading(false))
	}, [])


	useEffect(() => {
		if (diameterSelected) {
			updateCurrentStep({
				rank: currentStep.rank,
				subStepKey: currentStep.subStepKey,
				firstSubStep: currentStep.firstSubStep,
				lastSubStep: currentStep.lastSubStep,
				values: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_DIAMETER_MM,
						value: diameterSelected.inside_diameter_mm
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_DIAMETER_INCH,
						value: diameterSelected.inside_diameter_inch
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_DIAMETER_SIZE,
						value: diameterSelected.inside_diameter_size
					}
				]
			})
		}
	}, [diameterSelected])

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				(diameters && diameters.length > 0) ? diameters.map(opt => (
						<CheckRadioButton key={`${opt.inside_diameter_mm}-${opt.inside_diameter_inch}-${opt.inside_diameter_size}`} value={opt} itemSelected={diameterSelected} setSelection={setDiameterSelected}>
							<InsideDiameterOption>{t('global.unit.diameter.mm', { diameter: opt.inside_diameter_mm })}</InsideDiameterOption>
							<InsideDiameterOption>{t('global.unit.diameter.inch', { diameter: opt.inside_diameter_inch })}</InsideDiameterOption>
							{opt.inside_diameter_size && (
								<InsideDiameterOption>{t('global.unit.diameter.size', { diameter: opt.inside_diameter_size })}</InsideDiameterOption>
							)}
						</CheckRadioButton>
					)
				) : <BasicError marginTop="0" message={t('errors.noData.insideDiameter')} />
			)}
		</>
	)
}

export default InsideDiameter
