import { styled } from '@mui/material'

const PrimaryTitle = styled('h4')<{recap?: boolean}>(
	({theme, recap}) => ({
		fontFamily: 'DIN_2014',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '24px',
		lineHeight: '32px',
		color: recap ? theme.palette.recap.main : theme.palette.text.primary
	})
)

export default PrimaryTitle