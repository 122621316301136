import React from 'react'
import { Stack, styled } from '@mui/material'

const HoseImageSection = styled(Stack)(
	({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxSizing: 'border-box',
		background: theme.palette.secondary.main,
		border: '1px solid rgba(0, 0, 0, 0.1)',
		borderRadius: '6px',
		padding: 2,
		width: 96,
		height: 96
	})
)

export default HoseImageSection