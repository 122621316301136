import { Radio, RadioProps, styled } from '@mui/material'
import React from 'react'

const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	alignItems: 'center',
	justifyContent: 'center',
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	height: '18px',
	width: '18px',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
		height: '18px',
		width: '18px'
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		height: '18px',
		width: '18px'
	}
}))

const BpCheckedIcon = styled(BpIcon)(
	({ theme }) => ({
		backgroundColor: theme.palette.primary.main,
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: '18px',
			height: '18px',
			backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
			content: '""'
		}
	})
)

const CustomRadio = styled(Radio)({
	padding: '6px'
})

const BpRadio = (props: RadioProps) => {
	return (
		<CustomRadio
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	)
}

export default BpRadio