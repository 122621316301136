import React, { FC } from 'react'
import { FormControlLabel, styled } from '@mui/material'
import BpRadio from '../BpRadio'


const BasicRadioBtnContainer = styled(FormControlLabel, {
		shouldForwardProp: (prop) => prop !== 'active' && prop !== 'padding'
	}
)<{ active: boolean, padding: string }>(
	({ theme, active, padding }) => ({
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		padding: padding,
		border: `${active ? '3px' : '1px'} solid ${theme.palette.assemblyPanel.card.disable}`,
		borderRadius: '6px',
		color: theme.palette.text.primary,
		height: '42px'
	})
)

const BasicRadioButton: FC<BasicRadioButtonType> = ({ label, itemSelected, setSelection, padding = '5px 20px 5px 14px' }) => {
	return (
		<BasicRadioBtnContainer padding={padding} active={itemSelected === label} label={label} control={<BpRadio />} value={label} onClick={() => setSelection(label)} checked={itemSelected === label} />
	)
}

export default BasicRadioButton