import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationFR from '../assets/locales/fr/translation.json';
import translationEN from '../assets/locales/en/translation.json';
import translationES from '../assets/locales/es/translation.json';
import translationDE from '../assets/locales/de/translation.json';
import translationIT from '../assets/locales/it/translation.json';

const resources = {
	en: {
		translation: translationEN
	},
	fr: {
		translation: translationFR
	},
	es: {
		translation: translationES
	},
	de: {
		translation: translationDE
	},
	it: {
		translation: translationIT
	}
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: ["en", "fr", "es", "de", "it"],
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;