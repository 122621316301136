import React, { FC } from 'react'
import { Checkbox, FormControlLabel, Stack, styled } from '@mui/material'

const Container = styled(Stack)(
	({ theme }) => ({
		flexDirection: 'row',
		alignItems: 'center',
		border: `1px solid ${theme.palette.assemblyPanel.card.disable}`,
		borderRadius: '6px',
		padding: '0 20px',
		color: theme.palette.text.primary,
		height: '42px'
	})
)

const CustomCheckbox = styled(Checkbox)(
	({ theme }) => ({
		height: '42px',
		color: theme.palette.primary.main,
		'&.Mui-checked': {
			color: theme.palette.primary.main,
		}
	})
)

const CheckboxInput: FC<CheckboxInputType> = ({ label, checked, setChecked }) => (
	<Container>
		<FormControlLabel
			control={
				<CustomCheckbox defaultChecked={checked} />
			}
			label={label}
			onChange={(event, checked) => setChecked(checked)}
		/>
	</Container>
)

export default CheckboxInput
