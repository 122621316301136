import React, { FC } from 'react'
import { Stack, styled } from '@mui/material'
import ErrorLabel from '../typo/ErrorLabel'

type BasicErrorType = { message: string, marginTop?: string }

const Container = styled(Stack, {
		shouldForwardProp: (prop: string) => prop !== 'marginTop'
	}
)<{ marginTop: string | undefined }>(
	({ marginTop }) => ({
		alignSelf: 'stretch',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: marginTop ? marginTop : '24px'
	})
)

const BasicError: FC<BasicErrorType> = ({ message, marginTop }) => {

	return (
		<Container marginTop={marginTop}>
			<ErrorLabel>{message}</ErrorLabel>
		</Container>
	)
}

export default BasicError
