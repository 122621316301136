import { get, post } from '../../../utils/services/Request'

/**
 * Fichier appelant les endpoints de l'api /api/hoses
 */

/**
 * Récupère la liste des standards pour les tuyaux
 */
export const getStandards = (): Promise<any> => get('/standards')

/**
 * Récupère la liste des medias pour les tuyaux
 */
export const getMedias = (standardFilter: string): Promise<any> => post('/medias', standardFilter)

/**
 * Récupère la liste des tuyaux en fonction des filtres
 * @param filters
 */
export const getHosesWithFilter = (filters: string): Promise<any> => post('/hoses', filters)