import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NUMBER_FORMAT } from '../../../../../../../utils/constants'
import LabelSubtitle from '../../../../../../typo/LabelSubtitle'
import { Stack, styled } from '@mui/material'
import { LOCAL_STORAGE_KEYS } from '../../../../../../../config/enums'

const SpecificityContainer = styled(Stack)({
	width: '36%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-end',
	borderLeft: '2px solid rgba(0, 0, 0, 0.1)',
	gap: '6px'
})

const Row = styled(Stack)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'flex-end',
	gap: '12px'
})

const OtherConfigurationItem = () => {
	const { t } = useTranslation()

	const hoseCuttingLength: number = useMemo(() => Number(localStorage.getItem(LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH)) - Number(localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_CONNEXION_LENGTH_MM)) - Number(localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_CONNEXION_LENGTH_MM)), [localStorage])

	const getAssemblyMaxWorkPressure = (() => {
		const hoseMaxWorkPressure = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE))
		const fittingAMaxWorkPressure = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_MAX_WORKING_PRESSURE))
		const fittingBMaxWorkPressure = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_MAX_WORKING_PRESSURE))

		if (fittingAMaxWorkPressure > 0
		&& fittingBMaxWorkPressure > 0) {
			return t('global.unit.pressure.bar', {
				pressure: Math.min(hoseMaxWorkPressure, fittingAMaxWorkPressure, fittingBMaxWorkPressure)
			})
		} else if (fittingAMaxWorkPressure === 0
			|| fittingBMaxWorkPressure === 0) {
			return t('components.productItem.otherConfig.noAssemblyMaxWorkPressure')
		} else {
			return ''
		}
	})

	return (
		<>
			<LabelSubtitle>{t('screens.selector.assembly.recap.otherConfig')}</LabelSubtitle>
			<SpecificityContainer>
				{localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE) && (
					<Row>
						<LabelSubtitle color="#828282">{t('components.productItem.otherConfig.assemblyMaxWorkPressure')}</LabelSubtitle>
						<LabelSubtitle>{getAssemblyMaxWorkPressure()}</LabelSubtitle>
					</Row>
				)}
				{localStorage.getItem(LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH) && (
					<Row>
						<LabelSubtitle color="#828282">{t('components.productItem.otherConfig.assemblyLength')}</LabelSubtitle>
						<LabelSubtitle>
							{t('global.unit.diameter.mm', { diameter: Number(localStorage.getItem(LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH)).toLocaleString(NUMBER_FORMAT) })}
						</LabelSubtitle>
					</Row>
				)}
				{localStorage.getItem(LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH) && (
					<Row>
						<LabelSubtitle color="#828282">{t('components.productItem.otherConfig.hoseCutting')}</LabelSubtitle>
						<LabelSubtitle>
							{t('global.unit.diameter.mm', { diameter: hoseCuttingLength.toLocaleString(NUMBER_FORMAT) })}
						</LabelSubtitle>
					</Row>
				)}
				{localStorage.getItem(LOCAL_STORAGE_KEYS.FITTINGS_ANGLE) && (
					<Row>
						<LabelSubtitle color="#828282">{t('components.productItem.otherConfig.fittingsAngle')}</LabelSubtitle>
						<LabelSubtitle>{`${localStorage.getItem(LOCAL_STORAGE_KEYS.FITTINGS_ANGLE)}°`}</LabelSubtitle>
					</Row>
				)}
			</SpecificityContainer>
		</>
	)
}

export default OtherConfigurationItem
