import { t } from 'i18next'

// Liste des standards des hoses
export const STANDARDS = [
	"-",
	"API 7K FSL0",
	"API Spec 16D",
	"API Spec 17E",
	"API 7K FSL0",
	"BfR class 2",
	"BS 5118/34310",
	"DIN 73411",
	"DIN 74310",
	"DIN EN 1829-10",
	"DIN EN 1829-13",
	"DIN EN 1829-14",
	"DIN EN 1829-15",
	"DIN EN 1829-2",
	"DIN EN 1829-4",
	"DIN EN 1829-6",
	"DM 21/03/73",
	"DM 220 26/04/93",
	"DNV",
	"EC 1935/2004",
	"ECE-R67",
	"EN 12115",
	"EN 1762",
	"EN 1765 TYPE S15",
	"EN 250",
	"EN 45545",
	"EN 45545-HL2 -R23",
	"EN 45545-HL2-R22 (Internal) -R23 (External)",
	"EN 45545-HL3",
	"EN 61386-1 for electrical resistance to perforation",
	"EN 853-1SN",
	"EN 853-2SN",
	"EN 854-2TE",
	"EN 856-4SH",
	"EN 856-4SP",
	"EN 857-1SC",
	"EN 857-2SC",
	"ISO 3861",
	"EU 10/2011 Class A, B and C",
	"European Directive EC 10/2011 Class A, B and C",
	"FDA 21 CFR 177.1550",
	"FDA 21 CFR 177.2600",
	"ISO 10993 Sections 5,10,11",
	"ISO 11237-1SC",
	"ISO 11237-2SC",
	"ISO 11237-R16",
	"ISO 1307",
	"ISO 13628-5",
	"ISO 1436 Typ 1",
	"ISO 1436 Type 2",
	"ISO 14693",
	"ISO 15540",
	"ISO 1825 Type B",
	"ISO 1825 type C",
	"ISO 1825 type E",
	"ISO 1825 type F",
	"ISO 18752",
	"ISO 23384",
	"ISO 23384-B-250/8",
	"ISO 3821",
	"ISO 3862-R13",
	"ISO 3862-4SH",
	"ISO 3862-4SP",
	"ISO 3862-R15",
	"ISO 3949-R18",
	"ISO 3949-R7",
	"ISO 3949-R8",
	"ISO 6134-2/A",
	"ISO 6134-1/A",
	"ISO 7840-A1",
	"MSHA compliant cover",
	"Pressure class H70 acc. to ISO/FDIS 19880-5",
	"REACH",
	"SAE 20 R2 class D1",
	"SAE 100 R16",
	"SAE 100 R18",
	"SAE 100 R1AT",
	"SAE 100 R2AT",
	"SAE 100 R4",
	"SAE 100 R7",
	"SAE 100 R8",
	"SAE 100 R9",
	"SAE J1402-A",
	"SAE J1527 type A1-15",
	"SAE J20R3 Class A",
	"SAE J517",
	"TRbF 131 part 2 par 5.5 (flame resistance)",
	"UL94 V-2",
	"UNI 7140",
	"USCG A1-15",
	"USP Class VI"
]

// Paramétrage du slider
export const CONF_PRESSURE_SLIDER = {
	MIN: 3,
	DEFAULT_VALUE: 3
}

export const STEP_SLIDER = 1

// Labels des champs qui nécessitent des extractions
export const LABEL_DATA_EXTRACTION = {
	VACUUM_PRESSURE_BAR: 'vacuum_pressure_bar',
	COLOR: 'color',
	STANDARD: 'standard',
	MATERIAL: 'material'
}

// Identifiant pour déterminer le mode du fitting
export enum SOURCE_VALUE {
	FITTING_A = 'FITTING_A',
	FITTING_B = 'FITTING_B'
}

export enum TYPE_PRESSURE {
	PRESSURE = 'PRESSURE',
	VACUUM = 'VACUUM'
}

// Liens présent sur la home
export enum HOME_LINKS {
	OUR_INDUSTRIES = 'https://www.parker.com/gb/en/divisions/polymer-hose-division-europe/resources.html',
	PRIVACY_POLICIES = 'https://www.parker.com/us/en/privacy-policies.html',
	PERSONAL_DATA_PRIVACY = 'https://www.parker.com/us/en/privacy-policies/personal-data-privacy.html',
	COOKIES = 'https://www.parker.com/us/en/privacy-policies/cookie-policy.html',
	ACTUALITY = 'https://www.parker.com/content/dam/Parker-com/Literature/Polymer-Hose-Division-Europe/Sales-and-Marketing-Bulletins/distribution-offer/EMEA_FCGE_PHDE_Premier_Legacy_Range_Flyer.pdf',
	LEGAL_TERMS = 'https://discover.parker.com/PHAA_Legal_information',
	TUTORIAL = 'https://discover.parker.com/PHAA_Tutorial',
	CONTACT = 'https://www.parker.com/gb/en/divisions/polymer-hose-division-europe/contact.html'

}
