import { styled, Typography } from '@mui/material'

const PageSubTitle = styled(Typography)(
	({ theme }) => ({
		margin: '24px 0',
		fontFamily: 'Pragmatica',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '48px',
		lineHeight: '64px',
		color: theme.palette.primary.main
	})
)

export default PageSubTitle