/**
 * Ensemble des méthodes permettant d'effectuer des requêtes au serveur
 */
import { SERVER_CONF } from '../../config/server'

// Enumeration des types de requêtes
export enum TypeRequest {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	DELETE = 'DELETE'
}

export const get = async <T>(path: string, config: RequestInit = {}): Promise<T> => {

	const { headers, ...others } = config

	let defaultHeaders = {
		'Accept': 'application/json',
		'Content-Type': 'application/json;charset=UTF-8'
	}

	// TODO : attente des informations concernant le token renvoyé par l'api MyParker
	let auth = {}

	const newConfig = {
		method: TypeRequest.GET,
		headers: {
			...defaultHeaders,
			...headers,
			...auth
		},
		...others
	}
	const url = `${SERVER_CONF.BASE_PATH}${path}`
	return fetch(url, newConfig)
		.then((response) => {
			if (response.ok) {
				try {
					return response.json()
				} catch (err) {
					return 'ok'
				}
			}
			return {}
		}).then((data) => {
			return data as T
		})
}

export const post = async <T>(path: string, contentBody: string | FormData): Promise<T> => {
	const newConfig = {
		body: contentBody,
		method: TypeRequest.POST,
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json;charset=UTF-8'
		}
	}
	const url = `${SERVER_CONF.BASE_PATH}${path}`
	return fetch(url, newConfig)
		.then((response) => {
			if (response.ok) {
				try {
					return response.json()
				} catch (err) {
					return 'ok'
				}
			}
			return {}
		}).then((data) => {
			return data as T
		})
}

export const fetchFile = async (path: string, body: string) => {
	const newConfig = {
		body: body,
		method: TypeRequest.POST,
		responseType: 'blob'
	}
	const url = `${SERVER_CONF.BASE_PATH}${path}`
	return fetch(url, newConfig)
		.then((response: Response) => {
			return response.blob()
		})
}