import { Stack, styled } from '@mui/material'

const SelectorContent = styled(Stack)
(
	({ theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '24px',
		margin: '24px',
		backgroundColor: theme.palette.secondary.main,
		border: '1px solid rgba(0, 0, 0, 0.1)',
		borderRadius: '12px',
		flex: 'none',
		alignSelf: 'stretch',
		flexGrow: 0,
		marginTop: 115,
	})
)

export default SelectorContent