import React from 'react'
import ReactDOM from 'react-dom/client'
import './config/i18n'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import { DistributorContextProvider } from './utils/contextes/DistributorContext'

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<DistributorContextProvider>
				<App />
			</DistributorContextProvider>
		</BrowserRouter>
	</React.StrictMode>
)
