import React, { useContext, useEffect, useState } from 'react'
import { Stack, styled } from '@mui/material'
import HomeSelectorAccess from './components/HomeSelectorAccess'
import Actuality from './components/Actuality'
import OurIndustries from './components/OurIndustries'
import CustomAd from './components/CustomAd'
import { useTranslation } from 'react-i18next'
import CardImage from './components/CardImage'
import backgroundImg from '../../assets/img/global/background.png'
import bannerImg from '../../assets/img/home/banner.png'
import parkerLeftImg from '../../assets/img/home/home_parker_left.jpg'
import parkerRightImg from '../../assets/img/home/home_parker_right.png'
import { DistributorContext } from '../../utils/contextes/DistributorContext'
import {checkStore} from "../../utils/services/storesApi";

const Container = styled(Stack)({
	display: 'flex',
	flexDirection: 'column',
	alignSelf: 'stretch',
	backgroundImage: `url(${backgroundImg})`,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundAttachment: 'fixed',
	backgroundSize: 'cover',
	flexGrow: 1
})

const Content = styled(Stack)(
	({ theme }) => ({
		backgroundColor: theme.palette.secondary.main,
		borderRadius: '16px',
		flexDirection: 'column',
		width: '70%',
		padding: '25px'
	})
)

const Banner = styled(Stack)({
	backgroundImage: `url(${bannerImg})`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundAttachment: 'fixed',
	alignSelf: 'stretch',
	borderRadius: '12px',
	aspectRatio: '2.19',
	height: 'auto',
	transform: 'scale(1)',
	marginBottom: '24px'
})

const CardContainer = styled(Stack)(
	({ theme }) => ({
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		padding: '48px',
		gap: '24px',
		backgroundColor: theme.palette.assemblyPanel.main,
		border: '2px solid #E4E4E4',
		borderRadius: '12px',
		alignSelf: 'stretch',
		alignItems: 'center',
		justifyContent: 'center'
	})
)

const GridContainer = styled(Stack)({
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fill, minmax(48%, 1fr))',
	boxSizing: 'border-box',
	alignSelf: 'stretch',
	flexWrap: 'wrap'
})

const Home = () => {
	const { t } = useTranslation()
	const { token, setStoreInformation } = useContext(DistributorContext)

	const [storeInfo, setStoreInfo] = useState<StoreType | null>(null)

	useEffect(() => {
		if (token) {
			checkStore(JSON.stringify({token: token}))
				.then((storeInformation: StoreType) => {
					setStoreInformation(storeInformation)
					setStoreInfo(storeInformation)
				})
				.catch(e => console.log(e))
		}
	}, [token])

	return (
		<Container>
			<Stack alignItems="center" marginTop="44px" marginBottom="74px">
				<HomeSelectorAccess />
				<Content>
					<Banner />
					<CardContainer marginBottom="24px"><Actuality /></CardContainer>
					<GridContainer>
						<CardImage margin="0 12px 12px 0" image={(storeInfo && storeInfo.home_img) ? ('/upload/'+storeInfo.home_img) : parkerLeftImg} />
						<CardImage margin="0 0 12px 12px" image={parkerRightImg} />
						<CardContainer margin="12px 12px 0 0">
							<CustomAd
								title={(storeInfo && storeInfo.home_title) ? storeInfo.home_title : t('screens.home.content.customAd.title')}
								message={(storeInfo && storeInfo.home_text) ? storeInfo.home_text : t('screens.home.content.customAd.message')}
								labelButton={(storeInfo && storeInfo.home_button_label) ? storeInfo.home_button_label : t('screens.home.content.customAd.button')}
								link={(storeInfo && storeInfo.home_button_url) ? storeInfo.home_button_url : ''}
							/>
						</CardContainer>
						<CardContainer margin="12px 0 0 12px"><OurIndustries /></CardContainer>
					</GridContainer>
				</Content>
			</Stack>
		</Container>
	)
}

export default Home
