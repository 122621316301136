import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiCircle } from 'react-icons/bi'
import { FaEdit } from 'react-icons/fa'
import { FcCheckmark } from 'react-icons/fc'
import { seeSelection, subStepIsComplete } from '../../../../utils/selectorUtils'
import { CurrentStepContext } from '../../../../utils/contextes/CurrentStepContext'
import { Stack, styled, Typography } from '@mui/material'

const ProgressCardItemContainer = styled(Stack, {
		shouldForwardProp: (prop: string) => prop !== 'active'
	}
)<{ active: boolean }>(
	({ active, theme }) => ({
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '6px 8px',
		gap: '12px',
		borderRadius: '8px',
		alignSelf: 'stretch',
		backgroundColor: active ? theme.palette.assemblyPanel.card.item.active : theme.palette.assemblyPanel.card.item.disable,
		height: '34px'
	})
)

const LeftLabel = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'active'
	}
)<{ active: boolean }>(
	({ theme, active }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: active ? 600 : 400,
		fontSize: '12px',
		lineHeight: '16px',
		marginLeft: '13px',
		color: theme.palette.text.primary
	})
)

const ValueLabel = styled(Typography)(
	({ theme }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 600,
		color: theme.palette.text.primary,
		fontSize: '12px',
		lineHeight: '18px'
	})
)

const ProgressCardItem: FC<ProgressCardItemType> = ({ substep }) => {
	const { t } = useTranslation()
	const context = useContext(CurrentStepContext)

	const [isDone, setDone] = useState(false)

	useEffect(() => {
		setDone(subStepIsComplete(substep))
	}, [context.currentStep])

	const icon = useMemo(() => {
		if (context.currentStep.subStepKey === substep.key) {
			return <FaEdit size="1.2rem" />
		}
		if (!isDone) {
			return <BiCircle size="1.2rem" />
		}
		return <FcCheckmark size="1.2rem" />
	}, [isDone, context.currentStep])

	const printValue = useMemo(() => {
		if (!isDone) {
			return '-'
		}
		return seeSelection(substep.key, t)
	}, [isDone])

	return (
		<ProgressCardItemContainer active={context.currentStep.subStepKey === substep.key}>
			<Stack flexDirection="row" alignItems="center" justifyContent="center">
				<Stack alignItems="center" justifyContent="center" paddingLeft={context.currentStep.subStepKey === substep.key ? '1px' : '0'}>
					{icon}
				</Stack>
				<LeftLabel active={context.currentStep.subStepKey === substep.key}>{t(substep.title)}</LeftLabel>
			</Stack>
			<ValueLabel>{printValue}</ValueLabel>
		</ProgressCardItemContainer>
	)
}

export default ProgressCardItem