import React from 'react'
import { Stack, styled } from '@mui/material'

const ProductImage = styled(Stack, {
		shouldForwardProp: (prop: string) => prop !== 'image'
	}
)<{ image: string }>(
	({ image }) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundImage: `url('${image}')`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition:'center',
		alignSelf: 'stretch',
		boxSizing: 'border-box',
		height: '100%',
		width: '100%',
		overflow: 'hidden',
	})
)
export default ProductImage