import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckRadioButton from '../../../../components/form/radio/check/CheckRadioButton'
import SectionColorized from '../SectionColorized'
import { getMedias, getStandards } from '../../services/hosesApi'
import { LOCAL_STORAGE_KEYS } from '../../../../config/enums'
import { STANDARDS } from '../../utils/constants'
import Loader from '../../../../components/global/Loader'
import { useCurrentStepContext } from '../../utils/utils'
import BasicError from '../../../../components/errors/BasicError'
import {Autocomplete, Stack, TextField} from '@mui/material'
import { SnackContext } from '../../../../utils/contextes/SnackContext'
import LabelSubtitle from "../../../../components/typo/LabelSubtitle";

const TransportedFluidContent: FC = () => {
	const { t } = useTranslation()
	const { currentStep, updateCurrentStep } = useCurrentStepContext()
	const { setVisibilitySnackbar } = useContext(SnackContext)

	const [checked, setChecked] = useState<string | null>(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MEDIA))
	const [standardSelected, setStandardSelected] = useState<string>('-')
	const [typeSelected, setTypeSelected] = useState<string>('-')
	const [medias, setMedias] = useState<Array<string>>([])
	const [standards, setStandards] = useState<Array<StandardType>>([])
	const [distinctStandards, setDistinctStandards] = useState<Array<string>>([])
	const [distinctTypes, setDistinctTypes] = useState<Array<string>>([])
	const [isLoading, setLoading] = useState<boolean>(true)

	const standardFilter = useMemo(() => {
		let obj : StandardType  = {
			standard_code: standardSelected && standardSelected !== STANDARDS[0]?standardSelected:null,
			type: typeSelected && typeSelected !== STANDARDS[0]?typeSelected:null
		}
		return obj
	}, [standardSelected, typeSelected])

	useEffect(() => {
		getStandards()
			.then((res: { standards: Array<StandardType> }) => {
				setStandards(res.standards)
				let ds : string[] = []
				res.standards.map(standard => {
					if (standard.standard_code && ds.indexOf(standard.standard_code) < 0){
						ds.push(standard.standard_code)
					}
				})
				let distinctStandards = ds.sort();
				distinctStandards.unshift('-')
				setDistinctStandards(distinctStandards)
				let dt : string[] = []
				res.standards.map(standard => {
					if (standard.type != null && dt.indexOf(standard.type) < 0){
						dt.push(standard.type)
					}
				})
				let distinctTypes = dt.sort();
				distinctTypes.unshift('-')
				setDistinctTypes(distinctTypes)
			})
			.catch(() => setVisibilitySnackbar(true))
			.finally(() => setLoading(false))
	}, [])


	useEffect(() => {
		getMedias(JSON.stringify(standardFilter))
			.then((res: { medias: Array<string> }) => setMedias(res.medias.sort()))
			.catch(() => setVisibilitySnackbar(true))
			.finally(() => setLoading(false))
	}, [standardFilter])

	useEffect(()=>{
		if (standardSelected === STANDARDS[0] || standardSelected === '') {
			let dt : string[] = []
			standards.map(standard => {
				if (standard.type != null && dt.indexOf(standard.type) < 0){
					dt.push(standard.type)
				}
			})
			let dTypes = dt.sort();
			dTypes.unshift('-')
			setDistinctTypes(dTypes)
		} else {
			let dt : string[] = []
			standards.map(standard => {
				if (standard.standard_code == standardSelected && standard.type != null && dt.indexOf(standard.type) < 0){
					dt.push(standard.type)
				}
			})
			let dTypes = dt.sort();
			dTypes.unshift('-')
			setDistinctTypes(dTypes)
		}
	}, [standardSelected])

	useEffect(()=>{
		if (typeSelected === STANDARDS[0] || typeSelected === '') {
			let ds : string[] = []
			standards.map(standard => {
				if (standard.standard_code && ds.indexOf(standard.standard_code) < 0){
					ds.push(standard.standard_code)
				}
			})
			let distinctStandards = ds.sort();
			distinctStandards.unshift('-')
			setDistinctStandards(distinctStandards)
		} else {
			let ds : string[] = []
			standards.map(standard => {
				if (standard.type === typeSelected && standard.standard_code && ds.indexOf(standard.standard_code) < 0){
					ds.push(standard.standard_code)
				}
			})
			let distinctStandards = ds.sort();
			distinctStandards.unshift('-')
			setDistinctStandards(distinctStandards)
		}
	}, [typeSelected])

	useEffect(() => {
		if (checked) {
			updateCurrentStep({
				rank: currentStep.rank,
				subStepKey: currentStep.subStepKey,
				firstSubStep: currentStep.firstSubStep,
				lastSubStep: currentStep.lastSubStep,
				values: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MEDIA,
						value: checked.replace('_', ' ')
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_STANDARD,
						value: standardSelected !== '-' ? standardSelected : null
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_TYPE,
						value: typeSelected !== '-' ? typeSelected : null
					}
				]
			})
		}
	}, [checked, standardSelected, typeSelected])

	return isLoading ? <Loader /> : (
		<>
			<SectionColorized>
				<LabelSubtitle color="#262626" marginBottom="4px">{t('screens.selector.hose.transportedFluid.label')}</LabelSubtitle>
				<div  style={{display:'flex', flexDirection: 'row', gap:20}}>
				<Autocomplete
					options={distinctStandards}
					value={standardSelected}
					onChange={(event, newValue)=>{
						setStandardSelected(newValue??'')
					}}
					renderInput={(params) =>
						<>
							<LabelSubtitle color="#262626" marginBottom="4px">{t('screens.selector.hose.transportedFluid.standard.label')}</LabelSubtitle>
							<TextField sx={{ width: 320 }} {...params} />
						</>
					}
				/>
				<Autocomplete
					options={distinctTypes}
					value={typeSelected}
					onChange={(event, newValue)=>{
						setTypeSelected(newValue??'')
					}}
					renderInput={(params) =>
						<>
							<LabelSubtitle color="#262626" marginBottom="4px">{t('screens.selector.hose.transportedFluid.type.label')}</LabelSubtitle>
							<TextField sx={{ width: 320 }} {...params} />
						</>
					}
				/>
				</div>
			</SectionColorized>
			{(medias && medias.length > 0) ? medias.map(media => (
				<CheckRadioButton key={media.toString()} value={media} itemSelected={checked} setSelection={setChecked}>
					<span>{t(`data.medias.${media}`)}</span>
				</CheckRadioButton>
			)) : (
				<Stack marginTop="20px" alignItems="center" justifyContent="center" alignSelf="stretch">
					<BasicError message={t('errors.noData.medias')} />
				</Stack>
			)}
		</>
	)
}

export default TransportedFluidContent
