import { styled, Typography } from '@mui/material'

export const HomeTitle = styled(Typography)(
	({ theme }) => ({
		color: theme.palette.text.primary,
		fontFamily: 'DIN_2014',
		fontStyle: 'normal',
		fontSize: '24px',
		fontWeight: 700,
		lineHeight: '32px',
		marginBottom: '12px'
	})
)

export const HomeMessage = styled(Typography)(
	({ theme }) => ({
		color: theme.palette.text.primary,
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '22px'
	})
)

export const HomeMessageCard = styled(Typography)(
	({ theme }) => ({
		color: theme.palette.text.primary,
		fontFamily: 'DIN_2014',
		fontStyle: 'normal',
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '22px'
	})
)