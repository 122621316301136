import React from 'react'
import { Stack, styled } from '@mui/material'

const CardImage = styled(Stack, {
		shouldForwardProp: (prop: string) => prop !== 'image'
	}
)<{ image: string }>(
	({ image, theme }) => ({
		alignSelf: 'stretch',
		justifySelf: 'stretch',
		backgroundRepeat: 'no-repeat',
		backgroundImage: `url(${image})`,
		backgroundPosition: 'center',
		backgroundAttachment: 'fixed',
		backgroundSize: 'contain',
		borderRadius: '12px',
		border: `1px solid ${theme.palette.primary.main}`,
		aspectRatio: '1.5',
		height: 'auto',
		transform: 'scale(1)'
	})
)

export default CardImage
