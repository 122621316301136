import React from 'react'
import './ModalDetail.css'
import { useTranslation } from 'react-i18next'
import HoseDetailBody from './body/hose/HoseDetailBody'
import { Button } from '@mui/material'

const ModalDetail = (
	{
		setModalVisibility
	}: ModalHoseDetailType
) => {
	const { t } = useTranslation()

	return (
		<section className="modal-product-detail--container">
			<div className="modal-product-detail--header">
				<span className="modal-product-detail--header-label">TODO | Titre detail</span>
				<Button onClick={() => setModalVisibility(false)} variant="contained">{t('modal.detail.close')}</Button>
			</div>
			<div className="modal-product-detail--body">
				<h4 className="modal-product-detail--body-title">{t('modal.detail.body.title')}</h4>
				<HoseDetailBody />
			</div>
		</section>
	)
}

export default ModalDetail