import React from 'react'
import { Stack, styled } from '@mui/material'

const ImageContainer = styled(Stack, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ isSelected, theme }) => ({
		display: 'flex',
		alignItems:'center',
		justifyContent: 'center',
		boxSizing: 'border-box',
		background: theme.palette.secondary.main,
		border: `1px solid ${isSelected ? theme.palette.primary.main : 'rgba(0,0,0,0.1)'}`,
		borderRadius: '6px',
		flex: 'none',
		alignSelf: 'stretch',
		flexGrow: 0,
		height: '96px',
		overflow: 'hidden'
	})
)

export default ImageContainer