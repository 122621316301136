import React from 'react'
import { styled } from '@mui/material'

const NavbarLink = styled(`a`, {
		shouldForwardProp: (prop: string) => prop !== 'inButton'
	}
)<{ inButton?: boolean}>(
	({ inButton, theme }) => ({
		fontFamily: 'Inter',
		fontWeight: 600,
		fontStyle: 'normal',
		fontSize: '14px',
		padding: inButton ? '0' : '0 12px',
		lineHeight: '18px',
		textDecoration: 'none',
		color: inButton ? theme.palette.text.primary : theme.palette.text.secondary,
		cursor: 'pointer'
	})
)

export default NavbarLink
