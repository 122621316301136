import React, { useContext, useEffect, useMemo, useState } from 'react'
import SelectorNavbar from '../../components/navigation/selector/SelectorNavbar'
import AssemblyProgressPanel from '../../components/navigation/assemblyProgressPanel/AssemblyProgressPanel'
import { STEP_ASSEMBLY } from '../../utils/constants'
import { useTranslation } from 'react-i18next'
import ModalDetail from '../../components/modals/detail/ModalDetail'
import { nextStep, nextSubStep, previousStep, previousSubStep, stepIsDone } from '../../utils/selectorUtils'
import { CurrentStepContext } from '../../utils/contextes/CurrentStepContext'
import CrimpSource from '../../components/crimp/CrimpSource'
import PageContainer from '../../components/page/PageContainer'
import SelectorBody from './components/SelectorBody'
import SelectorContent from './components/SelectorContent'

const Selector = () => {
	const { t } = useTranslation()

	const context = useContext(CurrentStepContext)

	const [assemblyPanelIsVisible, setAssemblyPanelVisibility] = useState(true)
	const [modalDetailVisible, setModalDetailVisibility] = useState(false)

	// Extraction des données de l'étape principale
	const stepData = useMemo(() => {
		return STEP_ASSEMBLY.filter(step => step.rank === context?.currentStep?.rank)[0]
	}, [context?.currentStep])

	// Extraction des données de la sous-étape
	const subStepData = useMemo(() => {
		return stepData.substeps.filter(substep => substep.key === context?.currentStep?.subStepKey)[0]
	}, [stepData, context?.currentStep?.subStepKey])

	// Passage à l'étape ou la sous-étape suivante
	const next = () => {
		const newCurrentStep = nextSubStep(context?.currentStep!)
		if (newCurrentStep != context?.currentStep && !stepIsDone(STEP_ASSEMBLY.filter(it => it.rank === context?.currentStep?.rank!)[0])) {
			// passage à la sous-étape suivante
			context?.updateCurrentStep(newCurrentStep)
			if (newCurrentStep.subStepKey === 'recap') {
				setAssemblyPanelVisibility(false)
			}
		} else if (context?.currentStep?.rank! < STEP_ASSEMBLY.length) {
			// passage à l'étape majeure suivante
			context?.updateCurrentStep(nextStep(context?.currentStep!))
		}
	}

	// Passage à l'étape ou la sous-étape précédente
	const previous = () => {
		const newCurrentStep = previousSubStep(context?.currentStep!)
		if (newCurrentStep != context?.currentStep) {
			context?.updateCurrentStep(newCurrentStep)
		} else if (context?.currentStep?.rank! > 1) {
			context?.updateCurrentStep(previousStep(context?.currentStep!))
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [context?.currentStep?.subStepKey])

	return (
		<PageContainer direction="row" alignement="normal">
			<AssemblyProgressPanel
				assemblyPanelIsVisible={assemblyPanelIsVisible}
				setAssemblyPanelVisibility={setAssemblyPanelVisibility}
			/>
			<SelectorBody>
				<SelectorNavbar
					primaryLabel={t(stepData.title)}
					secondaryLabel={t(subStepData.title)}
					assemblyPanelVisible={assemblyPanelIsVisible}
					setAssemblyPanelVisibility={setAssemblyPanelVisibility}
					actionFirstBtn={next}
					actionSecondBtn={previous}
				/>
				<SelectorContent color="#FFF">
					{subStepData.view}
				</SelectorContent>
				{context?.currentStep!.subStepKey === 'recap' && (
					<CrimpSource />
				)}
			</SelectorBody>
			{modalDetailVisible && <ModalDetail setModalVisibility={setModalDetailVisibility} />}
		</PageContainer>
	)
}

export default Selector
