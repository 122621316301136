import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import RecapHeader from './header/RecapHeader'
import RecapProductItem from './productItem/RecapProductItem'

const AssemblyRecap: FC = () => {
	return (
		<>
			<RecapHeader />
			<RecapProductItem />
		</>
	)
}

export default AssemblyRecap
