import React, { createContext, FC, useState } from 'react'
import SnackbarError from '../../components/errors/SnackbarError'

type SnackContextType = {
	messageCode: string,
	setMessageCode: (message: string) => void,
	visibilitySnackbar: boolean,
	setVisibilitySnackbar: (show: boolean) => void
}

export const SnackContext = createContext<SnackContextType>({
	messageCode: 'errors.server.serviceUnavailable',
	setMessageCode(): void {
	},
	visibilitySnackbar: false,
	setVisibilitySnackbar(): void {
	}
})

export const SnackContextProvider: FC<{children: JSX.Element}> = ({ children }) => {
	const [messageCode, setMessageCodeState] = useState<string>('errors.server.serviceUnavailable')
	const [visibilitySnackbar, setSnackbarError] = useState<boolean>(false)

	const setMessageCode = (message: string) => setMessageCodeState(message)
	const setVisibilitySnackbar = (show: boolean) => setSnackbarError(show)

	return (
		<SnackContext.Provider value={{ messageCode, setMessageCode, visibilitySnackbar, setVisibilitySnackbar }}>
			{children}
			<SnackbarError messageCode={messageCode} isOpen={visibilitySnackbar} setIsOpen={setSnackbarError} />
		</SnackContext.Provider>
	)
}